import {
  GET_LANGUAGES,
  CHANGE_LANGUAGE,
  GET_COURSES_MENU,
  GET_COURSE_CONTEXTUAL_MENU,
  GET_STATIC_PAGES_MENU
} from "./appActions";
import { LOGIN, LOGOUT } from "../../auth/state/authActions";
import { getLanguageFromStorage } from "../../services/utilities/storage";

const defaultLanguage = { id: 2, code: "en" };
const selectedLanguage = getLanguageFromStorage();

const INITIAL_STATE = {
  currentLanguage: selectedLanguage || defaultLanguage,
  languages: [],
  areLanguagesFetching: false,
  areLanguagesFetched: false,
  isLanguageFetchError: false,
  coursesMenu: [],
  isCoursesMenuFetching: false,
  isCoursesMenuFetched: false,
  isCoursesMenuFetchError: false,
  courseContextualMenu: [],
  isCourseContextualMenuFetching: false,
  isCourseContextualMenuFetched: false,
  isCourseContextualMenuFetchError: false,
  homePageInMenu: null,
  staticPagesMenu: [],
  isStaticPagesMenuFetching: false,
  isStaticPagesMenuFetched: false,
  isStaticPagesMenuFetchError: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_LANGUAGES.REQUEST: {
      return {
        ...state,
        areLanguagesFetching: true,
        areLanguagesFetched: false,
        isLanguageFetchError: false
      };
    }

    case GET_COURSES_MENU.REQUEST: {
      return {
        ...state,
        isCoursesMenuFetching: true,
        isCoursesMenuFetched: false,
        isCoursesMenuFetchError: false
      };
    }

    case GET_COURSE_CONTEXTUAL_MENU.REQUEST: {
      return {
        ...state,
        isCourseContextualMenuFetching: true,
        isCourseContextualMenuFetched: false,
        isCourseContextualMenuFetchError: false
      };
    }

    case GET_STATIC_PAGES_MENU.REQUEST: {
      return {
        ...state,
        isStaticPagesMenuFetching: true,
        isStaticPagesMenuFetched: false,
        isStaticPagesMenuFetchError: false
      };
    }

    case GET_LANGUAGES.SUCCESS: {
      return {
        ...state,
        languages: action.payload,
        areLanguagesFetching: false,
        areLanguagesFetched: true,
        isLanguageFetchError: false
      };
    }

    case GET_COURSES_MENU.SUCCESS: {
      return {
        ...state,
        coursesMenu: action.payload.map(course => ({
          ...course,
          languageId: state.currentLanguage.id
        })),
        isCoursesMenuFetching: false,
        isCoursesMenuFetched: true,
        isCoursesMenuFetchError: false
      };
    }

    case GET_COURSE_CONTEXTUAL_MENU.SUCCESS: {
      return {
        ...state,
        courseContextualMenu: action.payload,
        isCourseContextualMenuFetching: false,
        isCourseContextualMenuFetched: true,
        isCourseContextualMenuFetchError: false
      };
    }

    case GET_STATIC_PAGES_MENU.SUCCESS: {
      const homePage = action.payload.find(x => x.isHomePage);

      return {
        ...state,
        homePageInMenu: { ...homePage, languageId: state.currentLanguage.id },
        staticPagesMenu: action.payload
          .filter(x => !x.isHomePage)
          .map(page => ({
            ...page,
            languageId: state.currentLanguage.id
          })),
        isStaticPagesMenuFetching: false,
        isStaticPagesMenuFetched: true,
        isStaticPagesMenuFetchError: false
      };
    }

    // Reset menu data because different user roles (and users)
    // see different data, but we have guards in NavBar.js
    // for minimizing api requests for menus. This will ensure that we
    // send those requests.
    case LOGIN.SUCCESS: {
      return {
        ...state,
        homePageInMenu: null,
        staticPagesMenu: [],
        coursesMenu: [],
        courseContextualMenu: []
      };
    }

    case GET_LANGUAGES.ERROR: {
      return {
        ...state,
        areLanguagesFetching: false,
        areLanguagesFetched: false,
        isLanguageFetchError: true
      };
    }

    case GET_COURSES_MENU.ERROR: {
      return {
        ...state,
        isCoursesMenuFetching: false,
        isCoursesMenuFetched: false,
        isCoursesMenuFetchError: true
      };
    }

    case GET_COURSE_CONTEXTUAL_MENU.ERROR: {
      return {
        ...state,
        isCourseContextualMenuFetching: false,
        isCourseContextualMenuFetched: false,
        isCourseContextualMenuFetchError: true
      };
    }

    case GET_STATIC_PAGES_MENU.ERROR: {
      return {
        ...state,
        isStaticPagesMenuFetching: false,
        isStaticPagesMenuFetched: false,
        isStaticPagesMenuFetchError: true
      };
    }

    case CHANGE_LANGUAGE: {
      return {
        ...state,
        currentLanguage: action.payload
      };
    }

    // Reset menu data because different user roles (and users)
    // see different data, but we have guards in NavBar.js
    // for minimizing api requests for menus. This will ensure that we
    // send those requests.
    case LOGOUT: {
      return {
        ...state,
        homePageInMenu: null,
        staticPagesMenu: [],
        coursesMenu: [],
        courseContextualMenu: []
      };
    }

    default:
      return state;
  }
}
