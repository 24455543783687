import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const useErrorStatusCheck = ({ selectorFn }) => {
  const errorStatus = useSelector(selectorFn);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // Error happens if the requested content doesn't exist (404)
    // or if the user doesn't have view access rights (403).
    if (errorStatus === 403 || errorStatus === 404) {
      setHasError(true);
    }

    if (!errorStatus) {
      setHasError(false);
    }
  }, [errorStatus]);

  return hasError;
};

export default useErrorStatusCheck;
