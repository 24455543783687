// TODO: maknuti ovu liniju prije builda za produkciju
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classnames from "classnames";

import {
  getCurrentLanguage,
  getCoursesMenu as getCoursesMenuSelector,
  getCourseContextualMenu as getCourseContextualMenuSelector,
  getStaticPagesMenu as getStaticPagesMenuSelector,
  getHomePageInMenu
} from "../../app/state/appSelectors";
import {
  getCoursesMenu,
  getCourseContextualMenu,
  getStaticPagesMenu
} from "../../app/state/appActions";
import useTranslations from "../../hooks/useTranslations";
import logoHome from "../../assets/img/logos-home.png";
import logo from "../../assets/img/logos.png";
import { PAGE_TYPES } from "../../services/constants/pageTypes";

const NavBar = ({ pageType }) => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const homePage = useSelector(getHomePageInMenu);
  const staticPagesMenu = useSelector(getStaticPagesMenuSelector);
  const coursesMenu = useSelector(getCoursesMenuSelector);
  const courseContextualMenu = useSelector(getCourseContextualMenuSelector);
  const t = useTranslations();
  const { id, pageId } = useParams();

  const dispatch = useDispatch();

  const [logoForRender, setLogoForRender] = useState(
    pageType === PAGE_TYPES.static && !id ? logoHome : logo
  );

  useEffect(() => {
    if (
      !staticPagesMenu.length ||
      staticPagesMenu.some(page => page.languageId !== currentLanguage.id)
    ) {
      dispatch(
        getStaticPagesMenu({ data: { languageId: currentLanguage.id } })
      );
    }
  }, [dispatch, currentLanguage.id, staticPagesMenu]);

  useEffect(() => {
    if (
      !coursesMenu.length ||
      coursesMenu.some(course => course.languageId !== currentLanguage.id)
    ) {
      dispatch(getCoursesMenu({ data: { languageId: currentLanguage.id } }));
    }
  }, [dispatch, currentLanguage.id, coursesMenu]);

  useEffect(() => {
    if (pageType === PAGE_TYPES.static && !id) {
      setLogoForRender(logoHome);
    } else {
      setLogoForRender(logo);
    }
  }, [pageType, id]);

  useEffect(() => {
    if (
      (pageType === PAGE_TYPES.courseInformative ||
        pageType === PAGE_TYPES.courseStatic ||
        pageType === PAGE_TYPES.courseWork ||
        pageType === PAGE_TYPES.courseLecture ||
        pageType === PAGE_TYPES.courseTest) &&
      id
    ) {
      if (
        !courseContextualMenu.length ||
        // There will always be at least one item with courseLanguageId
        // set (course informative page), so we can check against that
        // to see if the course has changed or not.
        courseContextualMenu.some(x => x.courseLanguageId === +id) === false
      ) {
        dispatch(getCourseContextualMenu({ data: { courseLanguageId: id } }));
      }
    }
  }, [dispatch, id, homePage, pageType, courseContextualMenu]);

  useEffect(() => {
    function changeClassesOnBody() {
      if (pageType === PAGE_TYPES.courseInformative) {
        document.body.classList.remove("home", "inner");
        document.body.classList.add("course");
      } else if (
        pageType === PAGE_TYPES.courseStatic ||
        pageType === PAGE_TYPES.courseWork ||
        pageType === PAGE_TYPES.courseLecture ||
        pageType === PAGE_TYPES.courseTest ||
        pageType === PAGE_TYPES.courseFinalForm
      ) {
        document.body.classList.remove("home");
        document.body.classList.add("course", "inner");
      } else if (
        pageType === PAGE_TYPES.login ||
        pageType === PAGE_TYPES.register ||
        pageType === PAGE_TYPES.contact ||
        (pageType === PAGE_TYPES.static && id)
      ) {
        document.body.classList.remove("home", "course");
        document.body.classList.add("inner");
      } else {
        document.body.classList.remove("course", "inner");
        document.body.classList.add("home");
      }
    }

    changeClassesOnBody();
  }, [pageType, id]);

  const getMenuItemClasses = pageId =>
    classnames({
      "menu-item": true,
      "current-menu-item":
        /**
         * Apply this class if we are on home page
         * (id is not defined in url, but pageId === homePage.id)
         * or if we are on one of the general static pages,
         * or if we are on contact page.
         */
        (pageType === PAGE_TYPES.static &&
          ((!id && pageId === homePage.id) || (+id && pageId === +id))) ||
        (pageType === PAGE_TYPES.contact && !id && !pageId)
    });

  const getContextualMenuItemClasses = page => {
    return classnames({
      "menu-item": true,
      "current-menu-item":
        pageType === PAGE_TYPES.courseInformative &&
        page.isInformativePage === true &&
        page.courseLanguageId === +id
          ? true
          : pageType === PAGE_TYPES.courseWork &&
            page.isWorkingPage === true &&
            page.courseLanguageId === +id
          ? true
          : pageType === PAGE_TYPES.courseStatic &&
            page.isStaticPage === true &&
            page.staticPageLanguageId === +pageId
          ? true
          : false
    });
  };
  const getContextualMenuItemUrl = page => {
    return page.isInformativePage
      ? `/courses/${page.courseLanguageId}`
      : page.isWorkingPage
      ? `/courses/${page.courseLanguageId}/course`
      : page.isStaticPage
      ? `/courses/${id}/page/${page.staticPageLanguageId}`
      : "#";
  };

  return (
    <section id="navBar" className="navBar">
      <div className="content flex">
        <Link to="/" className="logo" title="">
          MedOpen
        </Link>

        <div className="navBar-items flex">
          <div className="mobMenu-trigger">
            <div className="line" />
            <div className="line" />
            <div className="line" />
          </div>

          {staticPagesMenu.length > 0 ? (
            <nav>
              <ul className="menu">
                <li className={getMenuItemClasses(homePage?.id)}>
                  <Link to="/" aria-current="page" title={homePage?.menuTitle}>
                    {homePage?.menuTitle}
                  </Link>
                </li>

                {coursesMenu.length > 0 ? (
                  <li className="menu-item menu-item-has-children">
                    <Link to="#" title="Courses">
                      {t.courses}
                    </Link>
                    <ul className="sub-menu">
                      {coursesMenu.map(course => (
                        <li key={course.id}>
                          <Link
                            to={`/courses/${course.id}`}
                            title={course.menuTitle}
                          >
                            {course.menuTitle}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                ) : null}

                {staticPagesMenu.map(page => (
                  <li key={page.id} className={getMenuItemClasses(page.id)}>
                    <Link to={`/${page.id}`} title={page.menuTitle}>
                      {page.menuTitle}
                    </Link>
                  </li>
                ))}

                <li className={getMenuItemClasses(0)}>
                  <Link to="/contact" title={t.contact.title}>
                    {t.contact.title}
                  </Link>
                </li>
              </ul>
            </nav>
          ) : null}

          <img
            src={logoForRender}
            width={pageType === PAGE_TYPES.static && !id ? 284 : 238}
            height="50"
            alt="logos"
            className="logos"
          />
        </div>
      </div>

      {(pageType === PAGE_TYPES.courseInformative ||
        pageType === PAGE_TYPES.courseStatic ||
        pageType === PAGE_TYPES.courseWork ||
        pageType === PAGE_TYPES.courseLecture ||
        pageType === PAGE_TYPES.courseTest) && (
        <div className="context-menu">
          <div className="content text-block">
            {courseContextualMenu.length > 0 && (
              <ul className="menu">
                {courseContextualMenu.map((course, index) => (
                  <li
                    key={index}
                    className={getContextualMenuItemClasses(course)}
                  >
                    <Link
                      to={getContextualMenuItemUrl(course)}
                      title={course.menuTitle}
                    >
                      {course.menuTitle}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </section>
  );
};

export default NavBar;
