import { useMemo } from "react";
import { useParams } from "react-router-dom";

import useUrlFormValidityCheck from "./useUrlFormValidityCheck";
import { PAGE_TYPES } from "../services/constants/pageTypes";

const useRedirectUrlCalculation = ({ pageType }) => {
  const { id } = useParams();
  const { isCourseOrStaticPageIdFormValid } = useUrlFormValidityCheck({
    pageType
  });

  const redirectUrl = useMemo(() => {
    return pageType === PAGE_TYPES.static ||
      pageType === PAGE_TYPES.courseInformative
      ? "/"
      : pageType === PAGE_TYPES.courseWork ||
        pageType === PAGE_TYPES.courseStatic ||
        pageType === PAGE_TYPES.courseLecture ||
        pageType === PAGE_TYPES.courseTest ||
        pageType === PAGE_TYPES.courseFinalForm
      ? isCourseOrStaticPageIdFormValid
        ? `/courses/${id}`
        : "/"
      : "/";
  }, [id, isCourseOrStaticPageIdFormValid, pageType]);

  return redirectUrl;
};

export default useRedirectUrlCalculation;
