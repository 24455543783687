import axios from "axios";

import {
  refreshTokenThenSaveToStorage,
  logout
} from "../../auth/state/authActions";
import {
  getAuthStateFromStorage,
  removeAuthStateFromStorage
} from "../utilities/storage";
import { store } from "../../store";

const refreshTokenInterceptor = () => {
  axios.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      const initialRequest = error.config;
      const {
        // We read status from response.data.status instead of response.status
        // in order to differentiate between errors when the user is not logged in
        // vs errors when the user is not authorized to see something.
        // response.status is in both cases equal to 401.
        response: {
          data: { status }
        },
        config: { url }
      } = error;
      // const status = error.response.status;
      // const url = error.config.url;

      if (status !== 401) {
        return Promise.reject(error);
      }
      if (status === 401 && url === "/api/auth/token") {
        removeAuthStateFromStorage();
        store.dispatch(logout());
        return Promise.reject(error);
      }
      if (status === 401 && url !== "/api/auth/token") {
        const authData = getAuthStateFromStorage();
        const tokens = {
          token: authData?.token,
          refreshToken: authData?.refreshToken
        };
        return store
          .dispatch(refreshTokenThenSaveToStorage({ data: tokens }))
          .then(res => {
            if (!res) {
              return Promise.reject(error);
            }
            return Promise.resolve(axios(initialRequest));
          });
      }
    }
  );
};

export default refreshTokenInterceptor;
