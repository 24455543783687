export const getLanguages = state => state.app.languages;
export const languagesFetching = state => state.app.areLanguagesFetching;
export const languagesFetched = state => state.app.areLanguagesFetched;
export const languageFetchError = state => state.app.isLanguageFetchError;

export const getCurrentLanguage = state => state.app.currentLanguage;

export const getCoursesMenu = state => state.app.coursesMenu;
export const getCourseContextualMenu = state => state.app.courseContextualMenu;

export const getStaticPageTranslationLanguage = state =>
  state.staticPages.selectedItem?.language;
export const getCourseTranslationLanguage = state =>
    state.courses.selectedItem?.language;

export const getStaticPagesMenu = state => state.app.staticPagesMenu;
export const getHomePageInMenu = state => state.app.homePageInMenu;
export const getHomePageId = state => state.app.homePageInMenu?.id;
