import { useContext } from "react";

import TranslationContext from "../services/translations/TranslationContext";

const useTranslations = () => {
  const context = useContext(TranslationContext);

  if (!context) {
    throw new Error(
      "useTranslations must be used within a TranslationProvider"
    );
  }

  return context;
};

export default useTranslations;
