import React from "react";

import TopBar from "./TopBar";
import NavBar from "./NavBar";

const HeaderNav = ({ pageType }) => {
  return (
    <>
      <TopBar />
      <NavBar pageType={pageType} />
    </>
  );
};

export default HeaderNav;
