const generateActions = action => {
  return {
    ACTION: action,
    REQUEST: `${action}_REQUEST`,
    SUCCESS: `${action}_SUCCESS`,
    ERROR: `${action}_ERROR`
  };
};

export default generateActions;
