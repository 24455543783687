import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { getIsAuthenticated } from "../auth/state/authSelectors";

const PrivateRoute = ({
  component: Component,
  layout: Layout,
  roles,
  ...rest
}) => {
  const { pathname } = useLocation();
  const isLoggedIn = useSelector(getIsAuthenticated);
  // TODO: izvuci usera ako postoji
  // const currentUser = null;

  if (!isLoggedIn) {
    return (
      <Route {...rest}>
        <Redirect to={{ pathname: "/login", state: { from: pathname } }} />
      </Route>
    );
  }

  // TODO: check if route is restricted by role
  // for example:
  // if (roles && roles.indexOf(currentUser.role) === -1) {
  //   // role not authorised so redirect to home page
  //   return <Redirect to={{ pathname: "/" }} />;
  // }

  return (
    <Route {...rest}>
      {Layout ? (
        <Layout {...rest}>
          <Component />
        </Layout>
      ) : (
        <Component />
      )}
    </Route>
  );
};

export default PrivateRoute;
