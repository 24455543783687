export function login(data) {
  return {
    path: "auth/login",
    method: "POST",
    data
  };
}

export function register(data) {
  return {
    path: "auth/register",
    method: "POST",
    data
  };
}

export function verifyRegistrationQueryParams(data) {
  return {
    path: "auth/verify-registration-url-parameters",
    method: "POST",
    data
  };
}

export function getCoursesForRegistration(data) {
  return {
    path: `courses/lookup?languageId=${data.languageId}${
      data.courseLanguageId ? `&courseLanguageId=${data.courseLanguageId}` : ""
    }`
  };
}

export function refreshToken(data) {
  return {
    path: "auth/token",
    method: "POST",
    data
  };
}
