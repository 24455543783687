import {
  LOGIN,
  REGISTER,
  VERIFY_REGISTRATION_QUERY_PARAMS,
  LOGOUT,
  GET_COURSES_FOR_REGISTRATION,
  REFRESH_TOKEN
} from "./authActions";
import { getAuthStateFromStorage } from "../../services/utilities/storage";

const authState = getAuthStateFromStorage();

const INITIAL_STATE = {
  hasCheckedRegistrationUrlValidity: false,
  registrationUrlValid: false,
  registrationAdvancedFormFieldSetVisible: false,
  token: authState?.token || null,
  refreshTokenId: null,
  refreshToken: authState?.refreshToken || null,
  tokenExpiry: authState?.tokenExpiry || null,
  user: authState?.user || null,
  isLogging: false,
  isAuthenticated:
    authState?.user && Object.keys(authState?.user).length > 0 ? true : false,
  isRegistering: false,
  isRegistered: false,
  isError: false,
  errors: null,
  courses: [],
  areCoursesFetching: false,
  areCoursesFetched: false,
  isCourseFetchError: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN.REQUEST: {
      return {
        ...state,
        isLogging: true,
        isAuthenticated: false,
        isError: false
      };
    }

    case VERIFY_REGISTRATION_QUERY_PARAMS.REQUEST: {
      return {
        ...state,
        hasCheckedRegistrationUrlValidity: false
      };
    }

    case GET_COURSES_FOR_REGISTRATION.REQUEST: {
      return {
        ...state,
        areCoursesFetching: true,
        areCoursesFetched: false,
        isCourseFetchError: false
      };
    }

    case REGISTER.REQUEST: {
      return {
        ...state,
        isRegistering: true,
        isRegistered: false,
        isError: false
      };
    }

    case LOGIN.SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLogging: false,
        isAuthenticated: true,
        isError: false
      };
    }

    case VERIFY_REGISTRATION_QUERY_PARAMS.SUCCESS: {
      return {
        ...state,
        hasCheckedRegistrationUrlValidity: true,
        registrationUrlValid: action.payload.valid,
        registrationAdvancedFormFieldSetVisible:
          action.payload.isAdvancedRegistrationFormFieldSet
      };
    }

    case GET_COURSES_FOR_REGISTRATION.SUCCESS: {
      return {
        ...state,
        courses: action.payload,
        areCoursesFetching: false,
        areCoursesFetched: true,
        isCourseFetchError: false
      };
    }

    case REFRESH_TOKEN.SUCCESS: {
      return {
        ...state,
        ...action.payload,
        isLogging: false,
        isAuthenticated: true,
        isError: false
      };
    }

    case REGISTER.SUCCESS: {
      return {
        ...state,
        isRegistering: false,
        isRegistered: true,
        isError: false
      };
    }

    case LOGIN.ERROR:
    case REGISTER.ERROR: {
      return {
        ...state,
        isLogging: false,
        isAuthenticated: false,
        isRegistering: false,
        isRegistered: false,
        isError: true,
        errors: action.error?.response?.data?.errors || null
      };
    }

    case VERIFY_REGISTRATION_QUERY_PARAMS.ERROR: {
      return {
        ...state,
        hasCheckedRegistrationUrlValidity: true,
        registrationUrlValid: false,
        registrationAdvancedFormFieldSetVisible: false
      };
    }

    case GET_COURSES_FOR_REGISTRATION.ERROR: {
      return {
        ...state,
        areCoursesFetching: false,
        areCoursesFetched: false,
        isCourseFetchError: true
      };
    }

    case LOGOUT:
    case REFRESH_TOKEN.ERROR: {
      return {
        ...INITIAL_STATE,
        token: null,
        refreshToken: null,
        user: null,
        isAuthenticated: false
      };
    }

    default:
      return state;
  }
}
