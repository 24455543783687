import React, { Suspense } from "react";
import Loader from "../common/Loader";

const CommentLayout = ({ children }) => {
  return (
    <>
      <Suspense fallback={<Loader />}>{children}</Suspense>
    </>
  );
};

export default CommentLayout;
