const PAGE_TYPES = {
  static: "static",
  login: "login",
  register: "register",
  contact: "contact",
  courseInformative: "course:informative",
  courseStatic: "course:static",
  courseWork: "course:work",
  courseLecture: "course:lecture",
  courseTest: "course:test",
  courseFinalForm: "course:final-form"
};

export { PAGE_TYPES };
