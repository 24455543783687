import React from "react";

const DeleteModal = ({ onDelete = () => {}, onCancel = () => {} }) => {
  return (
    <div className="modal-cover">
      <div className="modal">
        <h3>Warning</h3>
        <p>Are you sure that you want to delete your comment?</p>
        <button
          onClick={onDelete}
          className="bttn bttn-sm primary rounded justCloseModal"
        >
          Yes
        </button>
        <a className="closeModal" href="/#" onClick={onCancel}>
          {null}
        </a>
      </div>
      <div className="closeCover"></div>
    </div>
  );
};

export default DeleteModal;
