import React, { Suspense, useEffect, cloneElement } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import HeaderNav from "../common/header-nav/HeaderNav";
import CoursePageHeader from "../common/header/CoursePageHeader";
import InnerPageHeader from "../common/header/InnerPageHeader";
import Loader from "../common/Loader";
import {
  getCourse,
  getLectureHeaderData,
  getTestHeaderData
} from "./state/courseSelectors";
import { getStaticPageHeaderData } from "../static-page/state/staticPageSelectors";
import { PAGE_TYPES } from "../services/constants/pageTypes";
import useUrlFormValidityCheck from "../hooks/useUrlFormValidityCheck";
import useRedirectUrlCalculation from "../hooks/useRedirectUrlCalculation";

const CourseLayout = ({ children, pageType }) => {
  const course = useSelector(getCourse);
  const staticPageHeaderData = useSelector(getStaticPageHeaderData);
  const lectureHeaderData = useSelector(getLectureHeaderData);
  const testHeaderData = useSelector(getTestHeaderData);
  const history = useHistory();
  const { isWholeUrlFormValid: isUrlValid } = useUrlFormValidityCheck({
    pageType
  });
  const redirectUrl = useRedirectUrlCalculation({ pageType });

  useEffect(() => {
    if (!isUrlValid) {
      history.replace(redirectUrl);
    }
  }, [history, isUrlValid, redirectUrl]);

  const renderHeader = () => {
    if (pageType === PAGE_TYPES.courseInformative) {
      return (
        <CoursePageHeader
          title={course?.informativeTitle || ""}
          subtitle={course?.informativeSubtitle || ""}
        />
      );
    }

    if (pageType === PAGE_TYPES.courseWork) {
      return (
        <InnerPageHeader
          title={course?.workTitle || ""}
          subtitle={course?.workSubtitle || ""}
        />
      );
    }

    if (pageType === PAGE_TYPES.courseStatic) {
      return <InnerPageHeader {...staticPageHeaderData} />;
    }

    if (pageType === PAGE_TYPES.courseLecture) {
      return <InnerPageHeader {...lectureHeaderData} />;
    }

    if (pageType === PAGE_TYPES.courseTest) {
      return <InnerPageHeader {...testHeaderData} />;
    }

    if (pageType === PAGE_TYPES.courseFinalForm) {
      return (
        <InnerPageHeader
          title={course?.informativeTitle || ""}
          subtitle={course?.informativeSubtitle || ""}
        />
      );
    }

    return null;
  };

  return (
    <>
      <HeaderNav pageType={pageType} />

      {renderHeader()}

      <Suspense fallback={<Loader />}>
        {cloneElement(children, { isUrlValid })}
      </Suspense>
    </>
  );
};

export default CourseLayout;
