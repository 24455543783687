// TODO: maknuti ovu liniju prije builda za produkciju
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";

import useTranslations from "../../hooks/useTranslations";
import { changeLanguage } from "../../app/state/appActions";
import { getLanguages, getCurrentLanguage } from "../../app/state/appSelectors";
import { logout } from "../../auth/state/authActions";
import { getUsername } from "../../auth/state/authSelectors";

import frenchFlag from "../../assets/img/icons/FR@2x.png";
import englishFlag from "../../assets/img/icons/GB@2x.png";
import italianFlag from "../../assets/img/icons/IT@2x.png";

const imgToLangMapper = {
  en: englishFlag,
  fr: frenchFlag,
  it: italianFlag
};

const TopBar = () => {
  const currentLanguage = useSelector(getCurrentLanguage);
  const languages = useSelector(getLanguages);
  const username = useSelector(getUsername);
  const t = useTranslations();
  const [langMenuOpened, setLangMenuOpened] = useState(false);
  const menuRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    /**
     * Check if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        if (langMenuOpened) {
          setLangMenuOpened(false);
        }
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef, langMenuOpened]);

  const toggleMenu = () => {
    setLangMenuOpened(!langMenuOpened);
  };

  const handleLanguageClick = language => event => {
    event.preventDefault();

    if (language.id !== currentLanguage.id) {
      dispatch(changeLanguage({ lang: language }));
      history.push("/");
    }
  };

  const listWrapperClasses = classnames({
    langSelect: true,
    openLang: langMenuOpened
  });

  const getMenuItemClasses = lang => {
    return classnames({
      "lang-item": true,
      "current-lang": lang === currentLanguage.code
    });
  };

  const handleLogout = event => {
    event.preventDefault();

    dispatch(logout());
    history.push("/");
  };

  return (
    <section id="topBar" className="topBar">
      <div className="content">
        <div className="slogan">{t.slogan}</div>

        <div className="right">
          <div className="loginHolder">
            {username ? (
              <>
                <span className="userName">{username}</span>
                <Link className="logOut" to="#" onClick={handleLogout}>
                  {t.logout}
                </Link>
              </>
            ) : (
              <>
                <Link className="login" to="/login">
                  {t.login}
                </Link>

                <Link className="register" to="/register">
                  {t.register}
                </Link>
              </>
            )}
          </div>

          <div className="langSelectWrapper">
            <ul
              ref={menuRef}
              className={listWrapperClasses}
              onClick={toggleMenu}
            >
              {languages.map(lang => (
                <li key={lang.id} className={getMenuItemClasses(lang.code)}>
                  <Link
                    lang={lang.code}
                    hrefLang={lang.code}
                    to="#"
                    onClick={handleLanguageClick(lang)}
                  >
                    <img
                      alt={lang.code}
                      src={imgToLangMapper[lang.code]}
                      width="20"
                      height="14"
                    />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TopBar;
