import React, { Suspense, useEffect, cloneElement } from "react";
import { useHistory } from "react-router-dom";

import HeaderNav from "../common/header-nav/HeaderNav";
import Loader from "../common/Loader";
import useUrlFormValidityCheck from "../hooks/useUrlFormValidityCheck";
import useRedirectUrlCalculation from "../hooks/useRedirectUrlCalculation";
import { PAGE_TYPES } from "../services/constants/pageTypes";

const StaticPageLayout = ({ children, pageType }) => {
  const { isWholeUrlFormValid: isUrlValid } = useUrlFormValidityCheck({
    pageType
  });
  const history = useHistory();
  const redirectUrl = useRedirectUrlCalculation({
    pageType: PAGE_TYPES.static
  });

  useEffect(() => {
    if (!isUrlValid) {
      history.replace(redirectUrl);
    }
  }, [history, isUrlValid, redirectUrl]);

  return (
    <>
      <HeaderNav pageType={pageType} />

      <Suspense fallback={<Loader />}>
        {cloneElement(children, { isUrlValid })}
      </Suspense>
    </>
  );
};

export default StaticPageLayout;
