export function getCourseDetails(data) {
  return {
    path: `courses/${data.id}`
  };
}

export function getCourseLectureList(data) {
  return {
    path: `lectures?courseLanguageId=${data.courseLanguageId}`
  };
}

export function getCourseLectureDetails(data) {
  return {
    path: `lectures/${data.id}`
  };
}

export function getLectureTest(data) {
  return {
    path: `tests/${data.lectureLanguageId}`
  };
}

export function postLectureTest(data) {
  return {
    path: "tests/submit",
    method: "POST",
    data: data
  };
}

export function postCourseFinalForm(data) {
  return {
    path: "tests/submit-final-form",
    method: "POST",
    data: data
  };
}
