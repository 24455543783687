import { callApi } from "../../services/api/api";

import { sendEmail as sendEmailApi } from "../apiConfig";
import generateActions from "../../services/utilities/generateActions";

export const SEND_EMAIL = generateActions("SEND_EMAIL");
export const RESET_CONTACT_STATE = "RESET_CONTACT_STATE";

export function sendEmail({ data }) {
  return {
    type: SEND_EMAIL.ACTION,
    promise: callApi(sendEmailApi(data))
  };
}

export function resetContactState() {
  return {
    type: RESET_CONTACT_STATE
  };
}
