import { callApi } from "../../services/api/api";

import {
  login as loginApi,
  register as registerApi,
  verifyRegistrationQueryParams as verifyRegistrationQueryParamsApi,
  getCoursesForRegistration as getCoursesForRegistrationApi,
  refreshToken as refreshTokenApi
} from "../apiConfig";
import {
  setAuthStateToStorage,
  removeAuthStateFromStorage
} from "../../services/utilities/storage";
import generateActions from "../../services/utilities/generateActions";

export const LOGIN = generateActions("LOGIN");
export const REGISTER = generateActions("REGISTER");
export const VERIFY_REGISTRATION_QUERY_PARAMS = generateActions(
  "VERIFY_REGISTRATION_QUERY_PARAMS"
);
export const GET_COURSES_FOR_REGISTRATION = generateActions(
  "GET_COURSES_FOR_REGISTRATION"
);
export const REFRESH_TOKEN = generateActions("REFRESH_TOKEN");

export const LOGOUT = "LOGOUT";

export function login({ data }) {
  return {
    type: LOGIN.ACTION,
    promise: callApi(loginApi(data))
  };
}

export function register({ data }) {
  return {
    type: REGISTER.ACTION,
    promise: callApi(registerApi(data))
  };
}

export function logout() {
  removeAuthStateFromStorage();

  return {
    type: LOGOUT
  };
}

export function verifyRegistrationQueryParams({ data }) {
  return dispatch => {
    dispatch({
      type: VERIFY_REGISTRATION_QUERY_PARAMS.ACTION,
      promise: callApi(verifyRegistrationQueryParamsApi(data))
    });
  };
}

export function getCoursesForRegistration({ data }) {
  return {
    type: GET_COURSES_FOR_REGISTRATION.ACTION,
    promise: callApi(getCoursesForRegistrationApi(data))
  };
}

export function refreshToken({ data }) {
  return {
    type: REFRESH_TOKEN.ACTION,
    promise: callApi(refreshTokenApi(data))
  };
}

export function loginThenSaveToStorage({ data }) {
  return (dispatch, getState) => {
    return dispatch(login({ data })).then(() => {
      const authState = getState().auth;
      setAuthStateToStorage({
        token: authState.token,
        refreshToken: authState.refreshToken,
        tokenExpiry: authState.tokenExpiry,
        user: { ...authState.user }
      });
    });
  };
}

export function refreshTokenThenSaveToStorage({ data }) {
  return (dispatch, getState) => {
    return dispatch(refreshToken({ data })).then(res => {
      const authState = getState().auth;
      setAuthStateToStorage({
        token: authState.token,
        refreshToken: authState.refreshToken,
        tokenExpiry: authState.tokenExpiry,
        user: { ...authState.user }
      });
      return res;
    });
  };
}
