import { callApi } from "../../services/api/api";

import { getStaticPageDetails as getStaticPageDetailsApi } from "../apiConfig";
import generateActions from "../../services/utilities/generateActions";

export const GET_STATIC_PAGE_DETAILS = generateActions(
  "GET_STATIC_PAGE_DETAILS"
);

export function getStaticPageDetails({ id }) {
  return {
    type: GET_STATIC_PAGE_DETAILS.ACTION,
    promise: callApi(getStaticPageDetailsApi(id))
  };
}
