import React, { useMemo } from "react";

import useTranslations from "../../hooks/useTranslations";

import JoditEditor from "./JoditEditor";

const HtmlEditor = ({ defaultValue, name, id, editorRef }, ...rest) => {
  const t = useTranslations();
  const currentLanguage = t.getLanguage();
  const config = useMemo(() => {
    return {
      autofocus: !defaultValue && id && id !== "editor-0" ? true : false,
      zIndex: 0,
      readonly: false,
      buttons: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "ul",
        "ol",
        "font",
        "link",
        "cut",
        "copy",
        "paste"
      ],
      buttonsMD: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "ul",
        "ol",
        "font",
        "link",
        "cut",
        "copy",
        "paste"
      ],
      buttonsXS: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "ul",
        "ol",
        "font",
        "link",
        "cut",
        "copy",
        "paste"
      ],
      removeButtons: [
        "image",
        "paint",
        "fullsize",
        "source",
        "dots",
        "table",
        "all"
      ],
      removeButtonsMD: [
        "image",
        "paint",
        "fullsize",
        "source",
        "dots",
        "table"
      ],
      removeButtonsXS: [
        "image",
        "paint",
        "fullsize",
        "source",
        "dots",
        "table"
      ],
      toolbarButtonSize: "small",
      theme: "light",
      enableDragAndDropFileToEditor: false,
      saveModeInCookie: false,
      spellcheck: true,
      width: "100%",
      minHeight: 60,
      direction: "auto",
      language: currentLanguage,
      debugLanguage: false,
      editorCssClass: "textarea",
      i18n: currentLanguage,
      tabIndex: -1,
      toolbar: true,
      enter: "p",
      useSplitMode: false,
      colorPickerDefaultTab: "background",
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      disablePlugins: ["stat"],
      events: {},
      textIcons: false,
      placeholder: t.comments.placeholder,
      toolbarAdaptive: false,
      iframe: true
    };
  }, [t, currentLanguage, id, defaultValue]);
  return (
    <JoditEditor
      defaultValue={defaultValue || ""}
      tabIndex={1}
      ref={r => (editorRef.current[id] = r)}
      id={id}
      config={config}
      name={name}
    />
  );
};

export default HtmlEditor;
