import { lazy } from "react";

import StaticPage from "../static-page/StaticPage";
import StaticPageLayout from "../static-page/StaticPageLayout";
import AuthLayout from "../auth/AuthLayout";
import CourseLayout from "../course/CourseLayout";
import { PAGE_TYPES } from "../services/constants/pageTypes";
import ContactLayout from "../contact/ContactLayout";

const Login = lazy(() => import("../auth/Login"));
const Register = lazy(() => import("../auth/Register"));
const CourseInformativePage = lazy(() => import("../course/InformativePage"));
const CourseStaticPage = lazy(() => import("../course/StaticPage"));
const CourseWorkPage = lazy(() => import("../course/WorkPage"));
const Lecture = lazy(() => import("../course/Lecture"));
const Test = lazy(() => import("../course/Test"));
const FinalFormPage = lazy(() => import("../course/FinalFormPage"));
const ContactForm = lazy(() => import("../contact/ContactForm"));

const routes = [
  {
    path: "/login",
    component: Login,
    layout: AuthLayout,
    private: false,
    pageType: PAGE_TYPES.login
  },
  {
    path: "/register",
    component: Register,
    layout: AuthLayout,
    private: false,
    pageType: PAGE_TYPES.register
  },
  {
    path: "/contact",
    component: ContactForm,
    layout: ContactLayout,
    private: false,
    pageType: PAGE_TYPES.contact
  },
  // TODO: mozda bolje napraviti ove nestane rute
  {
    path: "/courses/:id/course",
    component: CourseWorkPage,
    layout: CourseLayout,
    private: true,
    exact: true,
    pageType: PAGE_TYPES.courseWork
  },
  {
    path: "/courses/:id/page/:pageId",
    component: CourseStaticPage,
    layout: CourseLayout,
    private: true,
    pageType: PAGE_TYPES.courseStatic
  },
  {
    path: "/courses/:id/lecture/:lectureId/test",
    component: Test,
    layout: CourseLayout,
    private: true,
    pageType: PAGE_TYPES.courseTest
  },
  {
    path: "/courses/:id/lecture/:lectureId",
    component: Lecture,
    layout: CourseLayout,
    private: true,
    pageType: PAGE_TYPES.courseLecture
  },
  {
    path: "/courses/:id",
    component: CourseInformativePage,
    layout: CourseLayout,
    private: false,
    exact: true,
    pageType: PAGE_TYPES.courseInformative
  },
  {
    path: "/courses/:id/final-form",
    component: FinalFormPage,
    layout: CourseLayout,
    private: true,
    exact: true,
    pageType: PAGE_TYPES.courseFinalForm
  },
  {
    path: "/:id?",
    component: StaticPage,
    layout: StaticPageLayout,
    private: false,
    exact: true,
    pageType: PAGE_TYPES.static
  }
];

export default routes;
