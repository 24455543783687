import React from "react";
import moment from "moment";
import classNames from "classnames";

import ConditionalWrapper from "./ConditionalWrapper";
import CommentInput from "./components/CommentInput";
import useTranslations from "../hooks/useTranslations";
import { ROLES } from "../services/constants/roles";

const Post = ({
  avatar,
  comment,
  isChild = false,
  parent,
  defaultValues,
  render = () => {},
  onChange = () => {},
  onEdit = () => {},
  onDelete = () => {},
  onSubmit = () => {},
  onRemoveFile = () => {},
  addReply = () => {},
  onFileDownload = () => {},
  uploadProgress,
  toggleReply = false,
  validations,
  editing,
  editorRef,
  inputRef,
  currentId,
  commentInputRef,
  authenticatedUser
}) => {
  const t = useTranslations();
  const getReplyLinkClasses = () => {
    return classNames({
      reply: true,
      selected: toggleReply
    });
  };
  const canBeDeleted =
    (comment.canDeleteComment && !comment.children.length) ||
    authenticatedUser.roleId === ROLES.Administrators;

  return (
    <ConditionalWrapper
      condition={isChild}
      wrapper={children => (
        <div className="children">
          <ul>{children}</ul>
        </div>
      )}
    >
      <li className="post flex" id={`Post-${comment.id}`}>
        {!editing ? (
          <>
            <div className="avatar">
              <img data-role="user-avatar" src={avatar} alt="Avatar" />
            </div>
            <div className="post-body">
              {" "}
              <div className="post-header">
                <span className="author">{comment.userName}</span>
                {isChild && (
                  <span className="repliedTo">{parent.userName}</span>
                )}
                {` - ${moment(comment.createdOnDate)
                  .locale(t.getLanguage())
                  .fromNow()}`}
                {comment.canEditComment && (
                  <>
                    &nbsp; &ndash; &nbsp;
                    <a
                      href="/#"
                      title="Edit"
                      onClick={e => onEdit(e, comment.id)}
                    >
                      {t.comments.edit}
                    </a>
                  </>
                )}
                {canBeDeleted && (
                  <>
                    &nbsp; &ndash; &nbsp;
                    <a href="/#" title="Delete" onClick={onDelete}>
                      {t.comments.delete}
                    </a>
                  </>
                )}
                {comment.modifiedOnDate && (
                  <span className="edited-mark"> - (edited)</span>
                )}
              </div>
              <div
                className="post-content"
                dangerouslySetInnerHTML={{
                  __html: comment.text
                }}
              ></div>
              {comment.fileName && (
                <div className="post-attachment">
                  <strong>Attachment: </strong>
                  <a
                    href="/#"
                    title={comment.fileName}
                    onClick={onFileDownload}
                    rel="noopener"
                    download
                  >
                    {comment.fileName}
                  </a>
                </div>
              )}
              <div className="post-footer">
                {comment.level < 5 && (
                  <a
                    title="Reply"
                    className={getReplyLinkClasses()}
                    href="/#"
                    onClick={addReply}
                  >
                    {t.comments.reply}
                  </a>
                )}
              </div>
              {toggleReply ? (
                <CommentInput
                  editorRef={editorRef}
                  inputRef={inputRef}
                  avatar={avatar}
                  defaultValues={defaultValues}
                  uploadProgress={uploadProgress}
                  commentInputRef={commentInputRef}
                  onChange={onChange}
                  id={comment.id}
                  currentId={currentId}
                  onSubmit={onSubmit}
                  onRemove={onRemoveFile}
                  validations={validations}
                />
              ) : null}
            </div>
          </>
        ) : (
          <CommentInput
            editorRef={editorRef}
            inputRef={inputRef}
            avatar={avatar}
            defaultValues={defaultValues}
            uploadProgress={uploadProgress}
            commentInputRef={commentInputRef}
            onChange={onChange}
            id={comment.id}
            currentId={currentId}
            onSubmit={onSubmit}
            onRemove={onRemoveFile}
            validations={validations}
          />
        )}
        {comment.children.length > 0 && render(comment.children)}
      </li>
    </ConditionalWrapper>
  );
};

export default Post;
