import { callApi } from "../../services/api/api";

import {
  getCourseDetails as getCourseDetailsApi,
  getCourseLectureList as getCourseLectureListApi,
  getCourseLectureDetails as getCourseLectureDetailsApi,
  getLectureTest as getLectureTestApi,
  postLectureTest as postLectureTestApi,
  postCourseFinalForm as postCourseFinalFormApi
} from "../apiConfig";
import generateActions from "../../services/utilities/generateActions";

export const GET_COURSE_DETAILS = generateActions("GET_COURSE_DETAILS");

export const GET_COURSE_LECTURE_LIST = generateActions(
  "GET_COURSE_LECTURE_LIST"
);
export const GET_COURSE_LECTURE_DETAILS = generateActions(
  "GET_COURSE_LECTURE_DETAILS"
);

export const GET_LECTURE_TEST = generateActions("GET_LECTURE_TEST");

export const POST_LECTURE_TEST = generateActions("POST_LECTURE_TEST");

export const POST_COURSE_FINAL_FORM = generateActions("POST_COURSE_FINAL_FORM");

export function getCourseDetails({ data }) {
  return {
    type: GET_COURSE_DETAILS.ACTION,
    promise: callApi(getCourseDetailsApi(data))
  };
}

export function getCourseLectureList({ data }) {
  return {
    type: GET_COURSE_LECTURE_LIST.ACTION,
    promise: callApi(getCourseLectureListApi(data))
  };
}

export function getCourseLectureDetails({ data }) {
  return {
    type: GET_COURSE_LECTURE_DETAILS.ACTION,
    promise: callApi(getCourseLectureDetailsApi(data))
  };
}

export function getLectureTest({ data }) {
  return {
    type: GET_LECTURE_TEST.ACTION,
    promise: callApi(getLectureTestApi(data))
  };
}

export function postLectureTest({ data }) {
  return {
    type: POST_LECTURE_TEST.ACTION,
    promise: callApi(postLectureTestApi(data))
  };
}

export function postCourseFinalForm({ data }) {
  return {
    type: POST_COURSE_FINAL_FORM.ACTION,
    promise: callApi(postCourseFinalFormApi(data))
  };
}
