import { store } from "../store";
import { getFileUploadPercentage } from "./state/commentActions";

export function getComments(data) {
  return {
    path: `comments?staticPageLanguageId=${
      data.staticPageLanguageId || ""
    }&lectureLanguageId=${data.lectureLanguageId || ""}`
  };
}

export function getSelectedComment(id) {
  return {
    path: `comments/${id}`
  };
}

export function createComment(data) {
  return {
    path: `comments`,
    method: "POST",
    data,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: data.get("file")
      ? progressEvent => {
          const { loaded, total } = progressEvent;
          let percentage = Math.floor((loaded * 100) / total);
          // setTimeout(() => percentage, 1000);
          store.dispatch(getFileUploadPercentage(percentage));
        }
      : null
  };
}
export function updateComment(data) {
  return {
    path: `comments/${data.get("id")}`,
    method: "PUT",
    data,
    headers: {
      "Content-Type": "multipart/form-data"
    },
    onUploadProgress: data.get("file")
      ? progressEvent => {
          const { loaded, total } = progressEvent;
          let percentage = Math.floor((loaded * 100) / total);
          store.dispatch(getFileUploadPercentage(percentage));
        }
      : null
  };
}

export function deleteComment(id) {
  return {
    path: `comments/${id}`,
    method: "DELETE",
    data: id
  };
}
export function getFileLinkForDownload(id) {
  return {
    path: `comments/${id}/files`,
    data: id
  };
}

export function sendEmailNotificationOnReply(data) {
  return {
    path: "comments/email-notification",
    method: "POST",
    data
  };
}
