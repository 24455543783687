import { getAuthStateFromStorage } from "../utilities/storage";

export function authorizationHeader() {
  const authState = getAuthStateFromStorage();

  if (!authState) {
    return {};
  }

  if (authState?.token) {
    return { Authorization: `Bearer ${authState.token}` };
  } else {
    return {};
  }
}
