import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import useTranslations from "../../hooks/useTranslations";
import { getIsAuthenticated } from "../../auth/state/authSelectors";

const Header = ({ title = "", subtitle = "" }) => {
  const t = useTranslations();
  const isAuthenticated = useSelector(getIsAuthenticated);

  return (
    <>
      <header className="site-header simple">
        <div className="content">
          <div className="text">
            <p>{t.welcome}</p>
            <h1>{title}</h1>
            <p>{subtitle}</p>

            <div className="loginHolder">
              {isAuthenticated === false && (
                <>
                  <Link
                    to="/login"
                    className="bttn bttn-sm primary rounded login"
                  >
                    {t.login}
                  </Link>

                  <Link
                    to="/register"
                    className="bttn bttn-sm primary rounded inverted register"
                  >
                    {t.register}
                  </Link>
                </>
              )}
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
