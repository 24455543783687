import { callApi } from "../../services/api/api";

import {
  getLanguages as getLanguagesApi,
  getCoursesMenu as getCoursesMenuApi,
  getCourseContextualMenu as getCourseContextualMenuApi,
  getStaticPagesMenu as getStaticPagesMenuApi
} from "../apiConfig";
import generateActions from "../../services/utilities/generateActions";
import { setLanguageToStorage } from "../../services/utilities/storage";

export const GET_LANGUAGES = generateActions("GET_LANGUAGES");
export const GET_COURSES_MENU = generateActions("GET_COURSES_MENU");
export const GET_COURSE_CONTEXTUAL_MENU = generateActions(
  "GET_COURSE_CONTEXTUAL_MENU"
);
export const GET_STATIC_PAGES_MENU = generateActions("GET_STATIC_PAGES_MENU");

export const CHANGE_LANGUAGE = "CHANGE_LANGUAGE";

export function getLanguages() {
  return {
    type: GET_LANGUAGES.ACTION,
    promise: callApi(getLanguagesApi())
  };
}

export function changeLanguage({ lang }) {
  setLanguageToStorage(lang);

  return {
    type: CHANGE_LANGUAGE,
    payload: lang
  };
}

export function getCoursesMenu({ data }) {
  return {
    type: GET_COURSES_MENU.ACTION,
    promise: callApi(getCoursesMenuApi(data))
  };
}

export function getCourseContextualMenu({ data }) {
  return {
    type: GET_COURSE_CONTEXTUAL_MENU.ACTION,
    promise: callApi(getCourseContextualMenuApi(data))
  };
}

export function getStaticPagesMenu({ data }) {
  return {
    type: GET_STATIC_PAGES_MENU.ACTION,
    promise: callApi(getStaticPagesMenuApi(data))
  };
}
