import LocalizedStrings from "react-localization";

const strings = new LocalizedStrings({
  en: {
    login: "Login",
    register: "Register",
    logout: "Logout",
    slogan:
      "Virtual training course on coastal management in the Mediterranean",
    clientName: "Priority Actions Programme/Regional Activity Centre (PAP/RAC)",
    welcome: "Welcome to",
    courses: "Courses",
    cancel: "Cancel",
    basic: "Basic",
    advanced: "Advanced",
    male: "Male",
    female: "Female",
    other: "Other",
    startCourse: "Start course",
    score: "Score",
    points: "Points",
    startsOn: "starts on",
    totalScore: "Total score",
    goToDiscussion: "Go to discussion",
    test: "Take a test",
    repeatTest: "Repeat test",
    submitTest: "Submit test",
    previousLecture: "Previous lecture",
    nextLecture: "Next lecture",
    backToLecture: "Back to lecture",
    totalPoints: "Total points",
    scoredPoints: "You scored {0} points.",
    correct: "Correct!",
    incorrect: "Incorrect!",
    foundWords: "You found {0} words.",
    finish: "Finish",
    finalForm: {
      coursePassedBasic: "Congratulations, you have passed this course!",
      courseFailedBasic:
        "Unfortunately, you don't have enough points to pass this course!",
      coursePassedAdvanced:
        "Congratulations, this part of the course is finished!",
      scoredPoints: "You have {0} points of {1}.",
      getCertificate: "Get certificate",
      retakeTest: "Please retake the test!",
      formInstructions:
        "In order to enable us to improve the future runs, please fill in the following Post Evaluation form.",
      shareImprovementIdeas:
        "Share with us your ideas on how to improve {0} in the future...",
      questions:
        "Now, when you have gone through all the lectures, do you still have some remaining questions (or new ones!)?",
      comments:
        "Share with us your comments on this course! (What did you like, what did you dislike, what is missing, ideas for the next run, ...)",
      sectionDivider:
        "This part of the evaluation should focus on the following elements answering the following questions: What was good? What could have been better?",
      topicContent: "Topic / content",
      trainingMethodology: "Training methodology",
      documentsMaterial: "Documents / material",
      simulationGame: "Simulation Game",
      trainers: "Trainers",
      coordinationAndAssistance:
        "PAP/RAC Co-ordination and Technical Assistance",
      whatWasGood: "What was good?",
      whatCouldBeBetter: "What could have been better?",
      submit: "Submit",
      thankYou: "Thank you!",
      dataSubmitted: "Your data has been submitted."
    },
    auth: {
      loginInstructions: "Please enter your username and password:",
      registerInstructions: "Sign up for your new account:",
      thankYou: "Thank you for your registration. Please, wait for approval before login.",
      username: "Username",
      usernameHelperText: "Username should only contain letters, numbers and underscores.",
      password: "Password",
      passwordHelperText: "Use at least 8 characters, at least 1 uppercase, 1 number and 1 special character.",
      repeatPassword: "Repeat password",
      email: "E-mail",
      courseLevel: "Course level",
      course: "Course",
      message: "Please enter your details:",
      name: "First name",
      lastName: "Family name",
      gender: "Gender",
      dateOfBirth: "Date of birth",
      nationality: "Nationality",
      education: "Education background",
      workplace: "Workplace function",
      faculty: "Faculty country",
      country: "Country",
      city: "City",
      address: "Address",
      telephone: "Telephone number",
      skype: "Skype name",
      reason: "Why do you want to take this course?",
      expectation: "What do you expect from this course?"
    },
    errors: {
      required: "This field is required.",
      credentials: "Login failed. Incorrect login information or user not yet approved.",
      maxLength: "Value is too long.",
      minLength: "Value is too short.",
      username: "Invalid username format.",
      uniqueUsername: "Username already exists.",
      email: "Invalid email address or format.",
      uniqueEmail: "Email already exists.",
      confirmPassword: "Passwords don't match. Use at least 8 characters, at least 1 uppercase, 1 number and 1 special character.",
      weakPassword: "Password is too weak. Use at least 8 characters, at least 1 uppercase, 1 number and 1 special character.",
      fileType: "File type is not supported.",
      fileSize: "Max allowed file size is 10MB.",
      date: "Selected date must be in the past.",
      form: "Something went wrong. Please try again later.",
      userNotApproved:
        "Login failed. Please wait until you are approved by our administrators."
    },
    comments: {
      comments: "comments",
      placeholder: "Join the discussion",
      reply: "Reply",
      noPosts: "There are currently no posts.",
      selectFile: "Select file",
      noFile: "No selected file",
      uploadingFile: "Uploading file...",
      fileUploaded: "File uploaded.",
      submit: "Post",
      edit: "Edit",
      delete: "Delete"
    },
    contact: {
      title: "Contact",
      subTitle: "Contact us for more information:",
      name: "Name",
      email: "E-mail",
      message: "Enter your message",
      subject: "Subject",
      submit: "Send",
      thankYou:
        "Thank you for contacting us, we will reply as soon as possible."
    }
  },
  fr: {
    login: "Identification",
    register: "Enregistrement",
    logout: "Déconnection",
    slogan:
      "Cours de formation virtuelle sur la gestion des zones côtières en Méditerranée",
    clientName:
      "Centre d’activités régionales/Programme d’actions prioritaires (CAR/PAP)",
    welcome: "Bienvenue dans",
    courses: "Cours",
    cancel: "Annuler",
    basic: "Cours général",
    advanced: "Cours avancé",
    male: "Homme",
    female: "Femme",
    other: "Autre",
    startCourse: "Commencer le cours",
    score: "Score",
    points: "Points",
    startsOn: "commencer",
    totalScore: "Score total",
    goToDiscussion: "Aller à la discussion",
    test: "Faire le test",
    repeatTest: "Refaire le test",
    submitTest: "Envoyer le test à la correction",
    previousLecture: "Lecture précédente",
    nextLecture: "Lecture suivante",
    backToLecture: "Retour à la lecture",
    totalPoints: "Points totaux",
    scoredPoints: "Vous avez obtenu {0} points.",
    correct: "Correct!",
    incorrect: "Incorrect!",
    foundWords: "Vous avez trouvé {0} mots.",
    finish: "Terminé",
    finalForm: {
      coursePassedBasic: "Félicitations, vous avez passé ce cours avec succès!",
      courseFailedBasic:
        "Malheureusement, vous n'avez pas assez de points pour valider ce cours!",
      coursePassedAdvanced:
        "Félicitations, cette partie du cours est terminée!",
      scoredPoints: "Vous avez {0} points de {1}.",
      getCertificate: "Obtenir le certificat",
      retakeTest: "Veuillez repasser test!",
      formInstructions:
        "Afin de nous permettre d'améliorer les futures sessions, veuillez remplir le formulaire d'évaluation suivant.",
      shareImprovementIdeas:
        "Partagez avec nous vos idées sur la façon d'améliorer la {0} à l'avenir...",
      questions:
        "Maintenant que vous avez un aperçu de toutes les leçons, est-ce que certaines questions restent en suspens (ou en avez vous de nouvelles!)?",
      comments:
        "Partagez avec nous vos commentaires sur ce cours ! (Ce que vous avez aimé, ce que vous n'avez pas aimé, ce qui manque, des idées pour la prochaine session, ...)",
      sectionDivider:
        "Pour les éléments suivants, répondez aux questions suivantes : Qu'est ce qui était bien ? Qu'est ce qui aurait pu être mieux?",
      topicContent: "Thème / contenu",
      trainingMethodology: "Méthodologie de formation",
      documentsMaterial: "Documents / matériel",
      simulationGame: "Jeu de simulation",
      trainers: "Formateurs",
      coordinationAndAssistance:
        "Coordination et assistance technique du CAR/PAP",
      whatWasGood: "Qu'est ce qui vous a particulièrement plu?",
      whatCouldBeBetter: "Qu'est ce qui pourrait être amélioré?",
      submit: "Envoyer",
      thankYou: "Merci!",
      dataSubmitted: "Vos données ont été soumises."
    },
    auth: {
      loginInstructions:
        "Veuillez entre votre nom d'utilisateur et votre mot de passe:",
      registerInstructions: "Inscrivez-vous à votre nouveau compte:",
      thankYou: "Merci pour votre inscription. Merci de patienter jusqu’à ce qu’elle soit validée pour que vous puissiez vous connecter.",
      username: "Nom d'utilisateur",
      usernameHelperText: "Le nom d'utilisateur ne doit contenir que des lettres, des chiffres et des traits de soulignement.",
      password: "Mot de passe",
      passwordHelperText: "Utilisez au moins 8 caractères, dont au moins 1 majuscule, 1 chiffre et 1 caractère spécial.",
      repeatPassword: "Veuillez taper à nouveau votre mot de passe",
      email: "E-mail",
      courseLevel: "Niveau du cours",
      course: "Cours",
      message: "Veuillez saisir vos coordonnées:",
      name: "Prénom",
      lastName: "Nom de famille",
      gender: "Genre",
      dateOfBirth: "Date de naissance",
      nationality: "Nationalité",
      education: "Education",
      workplace: "Poste",
      faculty: "Faculté",
      country: "Pays",
      city: "Ville",
      address: "Adresse",
      telephone: "Numéro de téléphone",
      skype: "Nom d'utilisateur sur Skype",
      reason: "Pourquoi souhaitez-vous participer à ce cours?",
      expectation: "Qu'attendez-vous de ce cours?"
    },
    errors: {
      required: "Ce champ doit être rempli.",
      credentials: "Échec de la connexion. Informations de connexion incorrectes ou inscription non validée.",
      maxLength: "La valeur est trop longue.",
      minLength: "La valeur est trop courte.",
      username: "Format de nom d'utilisateur non valide.",
      uniqueUsername: "Ce nom d'utilisateur existe déjà.",
      email: "Le format de l'adresse e-mail n'est pas valide.",
      uniqueEmail: "Cet e-mail est déjà utilisé.",
      confirmPassword: "Les mots de passe ne sont pas identiques. Utilisez au moins 8 caractères, dont au moins 1 majuscule, 1 chiffre et 1 caractère spécial.",
      weakPassword: "La sécurité du mot de passe est trop faible. Utilisez au moins 8 caractères, dont au moins 1 majuscule, 1 chiffre et 1 caractère spécial.",
      fileType: "Ce type de fichier n'est pas pris en charge.",
      fileSize: "La taille maximale du fichier est de 10MB.",
      date: "La date sélectionnée doit être passée.",
      form: "Une erreur s'est produite. Merci de réessayer plus tard.",
      userNotApproved:
        "Vous n'avez pas réussi à vous identifier. Merci d'attendre que les administrateurs vous aient approuvés."
    },
    comments: {
      comments: "commentaires",
      placeholder: "Rejoindre la discussion",
      reply: "Répondre",
      noPosts: "Il n'y pas de postes à pourvoir actuellement.",
      selectFile: "Sélectionner un fichier",
      noFile: "Pas de fichier sélectionné",
      uploadingFile: "Téléchargement du fichier...",
      fileUploaded: "Fichier téléchargé.",
      submit: "Poster",
      edit: "Editer",
      delete: "Supprimer"
    },
    contact: {
      title: "Contacter",
      subTitle: "Veuillez nous contacter pour obtenir plus d'informations:",
      name: "Nom",
      email: "E-mail",
      message: "Entrez votre message",
      subject: "Sujet",
      submit: "Envoyer",
      thankYou:
        "Merci de nous avoir contacté, nous vous répondrons dès que possible."
    }
  },
  it: {
    login: "Accesso",
    register: "Registrazione",
    logout: "Disconnettersi",
    slogan:
      "Corso virtuale di formazione in gestione delle zone costiere del Mediterraneo",
    clientName:
      "Centro di attività regionale per il Programma di azioni prioritarie (PAP/RAC)",
    welcome: "Benvenuto a",
    courses: "Corsi",
    cancel: "Annulla",
    basic: "Di base",
    advanced: "Avanzato",
    male: "Maschio",
    female: "Femmina",
    other: "Altro",
    startCourse: "Inizia il corso",
    score: "Punto",
    points: "Punti",
    startsOn: "inizia",
    totalScore: "Punteggio totale",
    goToDiscussion: "Vai alla discussione",
    test: "Fai un test",
    repeatTest: "Ripeti il ​​test",
    submitTest: "Invia test",
    previousLecture: "Lezione precedente",
    nextLecture: "Prossima lezione",
    backToLecture: "Torna alla lezione",
    totalPoints: "Punti totali",
    scoredPoints: "Hai segnato {0} punti.",
    correct: "Corretto!",
    incorrect: "Scorretto!",
    foundWords: "Hai trovato {0} parole.",
    finish: "Finire",
    finalForm: {
      coursePassedBasic: "Congratulazioni, hai superato questo corso!",
      courseFailedBasic:
        "Sfortunatamente, non hai abbastanza punti per superare questo corso!",
      coursePassedAdvanced: "Complimenti, questa parte del corso è terminata!",
      scoredPoints: "Hai {0} punti di {1}.",
      getCertificate: "Ottieni il certificato",
      retakeTest: "Si prega di ripetere il test!",
      formInstructions:
        "Per consentirci di migliorare le esecuzioni future, compila il seguente modulo di post valutazione.",
      shareImprovementIdeas:
        "Condividi con noi le tue idee su come migliorare {0} in futuro...",
      questions:
        "Ora, quando hai passato tutte le lezioni, hai ancora alcune domande rimanenti (o nuove!)?",
      comments:
        "Condividi con noi i tuoi commenti su questo corso! (Cosa ti è piaciuto, cosa non ti è piaciuto, cosa manca, idee per la prossima corsa, ...)",
      sectionDivider:
        "Questa parte della valutazione dovrebbe concentrarsi sui seguenti elementi rispondendo alle seguenti domande: Cosa è stato buono? Cosa avrebbe potuto essere migliore?",
      topicContent: "Argomento / contenuto",
      trainingMethodology: "Metodologia di addestramento",
      documentsMaterial: "Documenti / materiale",
      simulationGame: "Gioco di simulazione",
      trainers: "Formatori",
      coordinationAndAssistance:
        "Coordinamento e assistenza tecnica di CAR/PAP",
      whatWasGood: "Che cosa era buono?",
      whatCouldBeBetter: "Cosa avrebbe potuto essere migliore?",
      submit: "Invia",
      thankYou: "Grazie!",
      dataSubmitted: "I tuoi dati sono stati inviati."
    },
    auth: {
      loginInstructions: "Inserisci il tuo nome utente e la parola d'ordine:",
      registerInstructions: "Registrarsi per creare un nuovo account:",
      thankYou: "Grazie per la Sua registrazione. Si prega di attendere l'approvazione prima di effettuare il login.",
      username: "Nome utente",
      usernameHelperText: "Il nome utente deve contenere solo lettere, numeri e caratteri di sottolineatura.",
      password: "Parola d'ordine",
      passwordHelperText: "Usa almeno 8 caratteri, almeno 1 maiuscolo, 1 numero e 1 carattere speciale.",
      repeatPassword: "Ripeti la parola d'ordine",
      email: "E-mail",
      courseLevel: "Livello del corso",
      course: "Corso",
      message: "Per favore inserisci i tuoi dati:",
      name: "Nome",
      lastName: "Cognome",
      gender: "Genere",
      dateOfBirth: "Data di nascita",
      nationality: "Nazione",
      education: "Educazione",
      workplace: "Luogo di lavoro (funzione)",
      faculty: "Facoltà",
      country: "Paese",
      city: "Città",
      address: "Indirizzo",
      telephone: "Numero di telefono",
      skype: "Nome Skype",
      reason: "Perché hai scelto di seguire questo corso?",
      expectation: "Cosa sono le tue aspettative da questo corso?"
    },
    errors: {
      required: "Questo campo modulo è obbligatorio.",
      credentials: "Login fallito. Informazioni di accesso errate o utente non ancora approvato.",
      maxLength: "Il valore è troppo lungo.",
      minLength: "Il valore è troppo breve.",
      username: "Formato nome utente non valido.",
      uniqueUsername: "Il nome utente esiste già.",
      email: "Formato dell'indirizzo email non valido.",
      uniqueEmail: "Email già esistente.",
      confirmPassword: "Le parole d'ordine non corrispondono. Usa almeno 8 caratteri, almeno 1 maiuscolo, 1 numero e 1 carattere speciale.",
      weakPassword: "La parola d'ordine è troppo debole. Usa almeno 8 caratteri, almeno 1 maiuscolo, 1 numero e 1 carattere speciale.",
      fileType: "Il tipo di file non è supportato.",
      fileSize: "La dimensione massima consentita per i file è di 10 MB.",
      date: "La data selezionata deve essere nel passato.",
      form: "Qualcosa è andato storto. Per favore riprova più tardi.",
      userNotApproved:
        "Accesso fallito. Attendi fino a quando non sarai approvato dai nostri amministratori."
    },
    comments: {
      comments: "commenti",
      placeholder: "Partecipa alla discussione",
      reply: "Rispondere",
      noPosts: "Al momento non ci sono messaggi.",
      selectFile: "Seleziona il file",
      noFile: "Nessun file selezionato",
      uploadingFile: "Caricamento file...",
      fileUploaded: "File caricato.",
      submit: "Inviare",
      edit: "Modificare",
      delete: "Elimina"
    },
    contact: {
      title: "Contatti",
      subTitle: "Contattateci per maggiori informazioni:",
      name: "Nome",
      email: "E-mail",
      message: "Inserisci il tuo messaggio",
      subject: "Soggetto",
      submit: "Invia",
      thankYou: "Grazie per averci contattato, ti risponderemo al più presto."
    }
  }
});

export default strings;
