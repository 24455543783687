import React, { useEffect, useRef, forwardRef, useLayoutEffect } from "react";
import Jodit from "jodit";
import "jodit/build/jodit.min.css";

const JoditEditor = forwardRef(
  ({ defaultValue, config, tabIndex, name, id }, ref) => {
    const textArea = useRef(null);

    useLayoutEffect(() => {
      if (ref) {
        if (typeof ref === "function") {
          ref(textArea.current);
        } else {
          ref.current = textArea.current;
        }
      }
    }, [ref]);
    useEffect(() => {
      const element = textArea.current;
      textArea.current = Jodit.make(element, config);
      textArea.current.value = defaultValue;
      textArea.current.workplace.tabIndex = tabIndex || -1;

      return () => {
        textArea.current.destruct();
        textArea.current = element;
      };
      //eslint-disable-next-line
    }, [config]);
    useEffect(() => {
      if (textArea && textArea.current) {
        textArea.current.value = defaultValue;
      }
    }, [textArea, defaultValue]);

    return <textarea ref={textArea} id={id} name={name}></textarea>;
  }
);

export default JoditEditor;
