import React from "react";
import useTranslations from "../../hooks/useTranslations";

const FileInput = ({
  inputRef,
  id,
  name,
  onChange = () => {},
  onRemove = () => {},
  fileName,
  file
}) => {
  const t = useTranslations();
  return (
    <>
      <input
        hidden
        ref={r => (inputRef.current[`file-${id || 0}`] = r)}
        type="file"
        id={`file-${id || 0}`}
        name={name}
        onChange={e => onChange(e, id)}
      />
      <button
        type="button"
        className="bttn"
        onClick={() => inputRef.current[`file-${id || 0}`].click()}
      >
        {t.comments.selectFile}
      </button>
      &nbsp;
      <span className="fileName" name="fileName">
        {file?.name || fileName || t.comments.noFile}
      </span>
      {(inputRef.current[`file-${id || 0}`]?.value || fileName) && (
        <>
          &nbsp;
          <a
            href="/#"
            className="remove-upload"
            title="Remove"
            onClick={e => onRemove(e, id)}
          >
            {null}
          </a>
        </>
      )}
    </>
  );
};

export default FileInput;
