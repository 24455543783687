import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";

import HeaderNav from "../common/header-nav/HeaderNav";
import Header from "../common/header/InnerPageHeader";
import Loader from "../common/Loader";
import useTranslations from "../hooks/useTranslations";

const AuthLayout = ({ children, pageType }) => {
  const { pathname } = useLocation();
  const t = useTranslations();

  const headerTitle = pathname.endsWith("/login") ? t.login : t.register;

  return (
    <>
      <HeaderNav pageType={pageType} />

      <Header title={headerTitle} />

      <Suspense fallback={<Loader />}>{children}</Suspense>
    </>
  );
};

export default AuthLayout;
