import { useCallback } from "react";

const useTransformList = ({ list = [] }) => {
  const getNestedList = useCallback(() => {
    let hash = {},
      nestedCommentTree = [];
    list.forEach(x => {
      hash[x.id] = x;
      x.children = [];
    });
    list.forEach(x => {
      x.parentCommentId && hash[x.parentCommentId]
        ? hash[x.parentCommentId].children.push(x)
        : nestedCommentTree.push(x);
    });
    return nestedCommentTree;
  }, [list]);

  return { getNestedList };
};

export default useTransformList;
