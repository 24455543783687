export const getCourse = state => state.courses.selectedItem;
export const getCourseId = state => state.courses.selectedItem?.id;
export const getCourseName = state =>
  state.courses.selectedItem?.informativeTitle;
export const getIsCourseLoading = state => state.courses.isItemFetching;
export const getCourseFetchErrorStatus = state =>
  state.courses.itemFetchErrorStatus;

export const getLectures = state => state.courses.lecturesList;

export const getLecture = state => state.courses.selectedLecture;
export const getLectureFetchErrorStatus = state =>
  state.courses.lectureFetchErrorStatus;
export const getLectureHeaderData = state => ({
  title: state.courses.selectedLecture?.title || "",
  subtitle: state.courses.selectedLecture?.subtitle || ""
});
export const getIsLectureLoading = state => state.courses.isLectureFetching;

export const getLectureTest = state => state.courses.selectedTest;
export const getIsLectureTestLoading = state => state.courses.isTestFetching;
export const getLectureTestFetchErrorStatus = state =>
  state.courses.testFetchErrorStatus;

export const getTestHeaderData = state => ({
  title: state.courses.selectedTest?.title || "",
  subtitle: state.courses.selectedTest?.subtitle || ""
});

export const getIsLectureTestSubmitting = state =>
  state.courses.isTestSubmitting;
export const getIsLectureTestSubmitted = state => state.courses.isTestSubmitted;

export const getIsFinalFormSubmitting = state =>
  state.courses.isFinalFormSubmitting;
export const getIsFinalFormSubmitted = state =>
  state.courses.isFinalFormSubmitted;
