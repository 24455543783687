import { useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";

import { PAGE_TYPES } from "../services/constants/pageTypes";

const useUrlFormValidityCheck = ({ pageType }) => {
  const {
    id: courseOrStaticPageId,
    lectureId: courseLectureId,
    pageId: courseStaticPageId
  } = useParams();
  const { pathname } = useLocation();

  const isUrlFormValid = useMemo(() => {
    let isWholeUrlFormValid =
      (courseOrStaticPageId && Number.isFinite(+courseOrStaticPageId)) ||
      (!courseOrStaticPageId &&
        pathname === "/" &&
        pageType === PAGE_TYPES.static);
    const isCourseOrStaticPageIdFormValid = isWholeUrlFormValid;

    if (pageType === PAGE_TYPES.courseStatic) {
      isWholeUrlFormValid =
        isWholeUrlFormValid &&
        courseStaticPageId &&
        Number.isFinite(+courseStaticPageId);
    }

    if (
      pageType === PAGE_TYPES.courseLecture ||
      pageType === PAGE_TYPES.courseTest
    ) {
      isWholeUrlFormValid =
        isWholeUrlFormValid &&
        courseLectureId &&
        Number.isFinite(+courseLectureId);
    }

    return {
      isCourseOrStaticPageIdFormValid,
      isWholeUrlFormValid
    };
  }, [
    courseOrStaticPageId,
    courseLectureId,
    courseStaticPageId,
    pathname,
    pageType
  ]);

  return isUrlFormValid;
};

export default useUrlFormValidityCheck;
