import React, { Suspense } from "react";

import HeaderNav from "../common/header-nav/HeaderNav";
import Header from "../common/header/InnerPageHeader";
import Loader from "../common/Loader";
import useTranslations from "../hooks/useTranslations";

const ContactLayout = ({ children, pageType }) => {
  const t = useTranslations();
  return (
    <>
      <HeaderNav pageType={pageType} />

      <Header title={t.contact.title} />

      <Suspense fallback={<Loader />}>{children}</Suspense>
    </>
  );
};

export default ContactLayout;
