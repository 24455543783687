import React, { useEffect, Suspense, useMemo } from "react";
import {
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "moment/locale/it";
import "moment/locale/fr";
import queryString from "query-string";

import Footer from "../common/Footer";
import Loader from "../common/Loader";
import PrivateRoute from "../navigation/PrivateRoute";
import routes from "../navigation/routes";
import { getLanguages, changeLanguage } from "./state/appActions";
import {
  getCurrentLanguage,
  getLanguages as getLanguagesFromStore,
  getStaticPageTranslationLanguage,
  getCourseTranslationLanguage
} from "./state/appSelectors";
import useTranslations from "../hooks/useTranslations";
import useForceUpdate from "../hooks/useForceUpdate";
import {
  logout,
  refreshTokenThenSaveToStorage,
  verifyRegistrationQueryParams
} from "../auth/state/authActions";
import {
  hasCheckedUrlValidity,
  getIsRegistrationUrlValid,
  getCourseCodeLanguage
} from "../auth/state/authSelectors";
import refreshTokenInterceptor from "../services/api/refreshTokenInterceptor";
import { getAuthStateFromStorage } from "../services/utilities/storage";

const App = () => {
  const forceUpdate = useForceUpdate();
  const t = useTranslations();
  const history = useHistory();
  const currentLanguage = useSelector(getCurrentLanguage);
  const dispatch = useDispatch();
  const isChecked = useSelector(hasCheckedUrlValidity);
  const isValid = useSelector(getIsRegistrationUrlValid);
  const courseLanguage = useSelector(getCourseCodeLanguage);
  const staticPageLanguage = useSelector(getStaticPageTranslationLanguage);
  const courseTranslationLanguage = useSelector(getCourseTranslationLanguage);
  const languages = useSelector(getLanguagesFromStore);
  const { pathname, search } = useLocation();

  /**
   * Registration URL form is valid if there are no query params
   * or if courseLanguageId and roleId
   * are both specified and valid numbers.
   */
  const isRegisterUrlFormValid = useMemo(() => {
    if (search) {
      const queryParams = queryString.parse(search);

      const isCourseLanguageIdValid =
        queryParams.courseLanguageId &&
        Number.isFinite(+queryParams.courseLanguageId);
      const isRoleIdValid =
        queryParams.roleId && Number.isFinite(+queryParams.roleId);

      return isCourseLanguageIdValid && isRoleIdValid;
    } else {
      return true;
    }
  }, [search]);

  useEffect(() => {
    let authTokenState = getAuthStateFromStorage();
    if (authTokenState && new Date(authTokenState.tokenExpiry) < new Date()) {
      dispatch(refreshTokenThenSaveToStorage({ data: authTokenState })).then(
        res => {
          if (!res) {
            dispatch(logout());
            history.push("/login");
          }
        }
      );
    }
  }, [dispatch, pathname, history]);

  useEffect(() => {
    dispatch(getLanguages());
  }, [dispatch]);

  useEffect(() => {
    if (currentLanguage && currentLanguage.code !== t.getLanguage()) {
      t.setLanguage(currentLanguage.code);

      forceUpdate();
    }
  }, [forceUpdate, t, currentLanguage]);
  useEffect(() => {
    if (
      pathname === "/register" &&
      search &&
      isValid &&
      isRegisterUrlFormValid &&
      courseLanguage &&
      courseLanguage !== currentLanguage.code
    ) {
      dispatch(
        changeLanguage({
          lang: languages.find(x => x.code === courseLanguage)
        })
      );
    } else if (
      staticPageLanguage &&
      staticPageLanguage !== currentLanguage.code
    ) {
      dispatch(
        changeLanguage({
          lang: languages.find(x => x.code === staticPageLanguage)
        })
      );
    } else if (
      courseTranslationLanguage &&
      pathname.includes("course") &&
      courseTranslationLanguage !== currentLanguage.code
    ) {
      dispatch(
        changeLanguage({
          lang: languages.find(x => x.code === courseTranslationLanguage)
        })
      );
    }
  }, [
    pathname,
    search,
    isValid,
    isRegisterUrlFormValid,
    courseLanguage,
    staticPageLanguage,
    courseTranslationLanguage,
    currentLanguage,
    languages,
    dispatch
  ]);

  useEffect(() => {
    if (pathname === "/register" && isRegisterUrlFormValid) {
      const queryParams = queryString.parse(search);

      dispatch(
        verifyRegistrationQueryParams({
          data: {
            courseLanguageId: +queryParams.courseLanguageId,
            roleId: +queryParams.roleId
          }
        })
      );
    }
  }, [dispatch, pathname, search, isRegisterUrlFormValid]);

  const getRoutes = () => {
    return routes.map((route, index) => {
      const { component: Component, layout: Layout, ...rest } = route;

      /**
       * If we are navigating to registration route,
       * we allow navigation only if regisration URL form
       * is valid, if we got the server's validity check response
       * and if query params' values are valid.
       * Redirect to homepage if URL form is not valid or if
       * query params' values are not valid.
       * Otherwise, don't render anything.
       */
      if (route.path === "/register" && pathname === route.path) {
        if (!isRegisterUrlFormValid || (isChecked && !isValid)) {
          return <Redirect key={index} to={{ pathname: "/" }} />;
        } else if (isRegisterUrlFormValid && isChecked && isValid) {
          return (
            <Route key={index} path={route.path} exact={route.exact}>
              {Layout ? (
                <Layout {...rest}>
                  <Component />
                </Layout>
              ) : (
                <Component />
              )}
            </Route>
          );
        } else {
          return null;
        }
      }

      if (route.private) {
        return (
          <PrivateRoute
            key={index}
            path={route.path}
            component={Component}
            layout={Layout}
            exact={route.exact}
            {...rest}
          />
        );
      }

      return (
        <Route key={index} path={route.path} exact={route.exact}>
          {Layout ? (
            <Layout {...rest}>
              <Component />
            </Layout>
          ) : (
            <Component />
          )}
        </Route>
      );
    });
  };

  refreshTokenInterceptor();

  if (pathname === "/register" && isRegisterUrlFormValid && !isChecked) {
    return null;
  }

  return (
    <>
      <Suspense fallback={<Loader />}>
        <Switch>
          {getRoutes()}
          <Redirect to="/" />
        </Switch>
      </Suspense>

      <Footer />
    </>
  );
};

export default App;
