import { applyMiddleware, createStore, combineReducers } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import promiseMiddleware from "./promiseMiddleware";
import app from "./app/state/appReducer";
import auth from "./auth/state/authReducer";
import staticPages from "./static-page/state/staticPageReducer";
import courses from "./course/state/courseReducer";
import comments from "./comments/state/commentReducer";
import contact from "./contact/state/contactReducer";

const rootReducer = combineReducers({
  app,
  auth,
  staticPages,
  courses,
  comments,
  contact
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk, promiseMiddleware))
);
