import React, { createContext, useMemo } from "react";

import translations from "./translations";

const TranslationContext = createContext();

const TranslationProvider = props => {
  const t = translations;

  const value = useMemo(() => t, [t]);

  return <TranslationContext.Provider value={value} {...props} />;
};

export { TranslationProvider };

export default TranslationContext;
