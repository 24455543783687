export function getLanguages() {
  return {
    path: "languages/lookup"
  };
}

export function getCoursesMenu(data) {
  return {
    path: `menu/static-pages/courses?languageId=${data.languageId}`
  };
}

export function getCourseContextualMenu(data) {
  return {
    path: `menu/course-context?courseLanguageId=${data.courseLanguageId}`
  };
}

export function getStaticPagesMenu(data) {
  return {
    path: `menu/static-pages?languageId=${data.languageId}`
  };
}
