import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import useTranslations from "../hooks/useTranslations";

import {
  getCommentsFromState,
  getFileUrl,
  getAuthenticatedUser,
  getUploadProgressPercentage
} from "./state/commentSelectors";
import {
  getComments,
  deleteCommentAndUpdateState,
  createCommentAndUpdateState,
  updateCommentAndUpdateState,
  getFileLinkForDownload,
  sendEmailNotificationOnReply,
  resetCommentState
} from "./state/commentActions";

import avatar from "../assets/img/icons/avatar.png";
import Post from "./Post";
import CommentInput from "./components/CommentInput";
import {
  validateFileType,
  validateFileSize
} from "./helpers/validateUploadedFile";
import useTransformList from "../hooks/useTransformList";
import DeleteModal from "./components/DeleteModal";

const initialState = {
  id: null,
  text: "",
  fileName: "",
  file: null,
  uploadProgress: 0
};

const List = ({ staticPageLanguageId = null, lectureLanguageId = null }) => {
  const dispatch = useDispatch();
  const t = useTranslations();
  let commentInputRef = useRef();
  let editorRef = useRef([]);
  let inputRef = useRef([]);
  const currentId = useRef();
  const idToDelete = useRef();
  const comments = useSelector(getCommentsFromState);
  const authenticatedUser = useSelector(getAuthenticatedUser);
  const selectedFile = useSelector(getFileUrl);
  const uploadProgress = useSelector(getUploadProgressPercentage);
  const [validations, setValidations] = useState({});

  const [toggleReply, setToggleReply] = useState({});
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [values, setValues] = useState({ 0: { ...initialState } });

  const [toggleEdit, setToggleEdit] = useState({});
  useEffect(() => {
    dispatch(
      getComments({
        data: { staticPageLanguageId, lectureLanguageId }
      })
    );
  }, [dispatch, staticPageLanguageId, lectureLanguageId]);

  useEffect(() => {
    if (selectedFile) {
      setTimeout(() => {
        window.location.href = selectedFile;
      }, 100);
      dispatch(resetCommentState());
    }
  }, [dispatch, selectedFile]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        commentInputRef.current &&
        !commentInputRef.current.contains(event.target)
      ) {
        if (Object.keys(toggleEdit).length > 0) {
          setToggleEdit({});
        }
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [commentInputRef, toggleEdit]);

  const { getNestedList } = useTransformList({ list: comments.list });

  const handleReplyClick = (e, id) => {
    e.preventDefault();
    setToggleReply(prevState => {
      return {
        ...prevState,
        [id]: !prevState[id]
      };
    });
    setValues(prevState => {
      return {
        ...prevState,
        [id]: initialState
      };
    });
  };

  const handleChange = (e, id) => {
    e.preventDefault();
    const { name, value, files } = e.target;
    if (name === "file") {
      let type = validateFileType(files[0]);
      let size = validateFileSize(files[0]);
      if (!type || !size) {
        setValidations(prevState => {
          return {
            ...prevState,
            [id || 0]: { type, size }
          };
        });
        e.target.value = null;
        setValues(prevState => {
          return {
            ...prevState,
            [id || 0]: {
              ...prevState[id || 0],
              file: "",
              fileName: ""
            }
          };
        });
        return;
      } else {
        setValidations({});
      }
    }
    setValues(prevState => {
      return {
        ...prevState,
        [id || 0]: {
          ...prevState[id || 0],
          [name]: name === "file" ? files[0] : value
        }
      };
    });
  };

  const handleEdit = (e, id) => {
    e.preventDefault();
    setToggleEdit(prevState => {
      return {
        ...prevState,
        [id]: !prevState[id]
      };
    });
    let comment = comments.list.find(x => x.id === id);
    setValues(prevState => {
      return {
        ...prevState,
        [id]: comment
      };
    });
    if (!toggleEdit[id] && validations[id]) {
      setValidations({});
    }
  };

  const closeModal = e => {
    e.preventDefault();
    setIsDeleteModalVisible(false);
    idToDelete.current = null;
  };
  const confirmDelete = e => {
    dispatch(
      deleteCommentAndUpdateState({
        id: idToDelete.current,
        data: { staticPageLanguageId, lectureLanguageId }
      })
    );

    closeModal(e);
  };

  const handleDelete = (e, id) => {
    e.preventDefault();
    idToDelete.current = id;
    setIsDeleteModalVisible(true);
  };

  const onRemoveFile = (e, id) => {
    e.preventDefault();
    setValues(prevState => {
      return {
        ...prevState,
        [id || 0]: {
          ...prevState[id || 0],
          file: "",
          fileName: ""
        }
      };
    });
    inputRef.current[`file-${id || 0}`].value = "";
  };

  const onFileDownload = (e, id) => {
    e.preventDefault();
    dispatch(getFileLinkForDownload(id));
  };

  const handlePostComment = parentOrSelf => {
    let commentText =
      editorRef.current[`editor-${parentOrSelf?.id || 0}`].value;

    if (!commentText) {
      return;
    }
    const formData = new FormData();

    let newComment = toggleReply[parentOrSelf.id] === true;
    // let editComment = toggleEdit[parentOrSelf.id] === true;

    formData.append("text", commentText);
    if (newComment || parentOrSelf === 0) {
      const { fileName, uploadProgress, ...rest } = values[
        parentOrSelf?.id || 0
      ];
      let payload = {
        ...rest,
        parentCommentId: parentOrSelf?.id || null,
        level: parentOrSelf ? parentOrSelf.level + 1 : null,
        staticPageLanguageId,
        lectureLanguageId,
        userId: 4
      };
      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null) {
          formData.append(key, value);
        }
      });
      dispatch(
        createCommentAndUpdateState({
          data: formData,
          pageData: { staticPageLanguageId, lectureLanguageId }
        })
      ).then(x => {
        if (parentOrSelf !== 0) {
          dispatch(
            sendEmailNotificationOnReply({
              data: {
                LanguageCode: t.getLanguage(),
                userName: parentOrSelf.userName,
                content:
                  editorRef.current[`editor-${parentOrSelf?.id || 0}`].value,
                replyUrl: window.location.href
              }
            })
          );
          inputRef.current[`file-${parentOrSelf.id}`].value = "";
          editorRef.current[`editor-${parentOrSelf.id}`].value = "";

          setToggleReply(prevState => {
            return {
              ...prevState,
              [parentOrSelf.id]: !prevState[parentOrSelf.id]
            };
          });
        } else {
          inputRef.current[`file-${0}`].value = "";
          editorRef.current[`editor-${0}`].value = "";
          setValues(prevState => {
            return {
              ...prevState,
              0: { ...initialState }
            };
          });
        }
      });
    } else {
      const { uploadProgress, parentCommentId, level, ...rest } = values[
        parentOrSelf?.id
      ];
      let payload = {
        ...rest,
        parentCommentId: parentCommentId,
        level: level,
        staticPageLanguageId,
        lectureLanguageId,
        userId: 1
      };
      Object.entries(payload).forEach(([key, value]) => {
        if (value !== null) {
          formData.append(key, value);
        }
      });
      formData.append("id", values[parentOrSelf.id].id);
      dispatch(
        updateCommentAndUpdateState({
          data: formData,
          pageData: { staticPageLanguageId, lectureLanguageId }
        })
      ).then(x => {
        inputRef.current[`file-${parentOrSelf ? parentOrSelf.id : 0}`].value =
          "";
        editorRef.current[
          `editor-${parentOrSelf ? parentOrSelf.id : 0}`
        ].value = "";
        setToggleEdit(prevState => {
          return {
            ...prevState,
            [values[parentOrSelf.id || 0].id]: !prevState[
              values[parentOrSelf.id || 0].id
            ]
          };
        });
      });
    }
    currentId.current = parentOrSelf.id || 0;
  };
  const renderComments = posts => {
    return posts?.map((post, index) => {
      let parent = post.parentCommentId
        ? comments.list.find(x => x.id === post.parentCommentId)
        : null;
      return (
        <Post
          key={index}
          avatar={avatar}
          comment={post}
          defaultValues={values[post.id || 0]}
          render={renderComments}
          isChild={parent ? true : false}
          parent={parent}
          addReply={e => handleReplyClick(e, post.id)}
          onChange={handleChange}
          onSubmit={() => handlePostComment(post)}
          onEdit={handleEdit}
          onDelete={e => handleDelete(e, post.id)}
          onFileDownload={e => onFileDownload(e, post.id)}
          onRemoveFile={onRemoveFile}
          toggleReply={toggleReply[post.id]}
          validations={validations[post.id]}
          uploadProgress={uploadProgress}
          editing={toggleEdit[post.id]}
          editorRef={editorRef}
          inputRef={inputRef}
          currentId={currentId}
          commentInputRef={commentInputRef}
          authenticatedUser={authenticatedUser}
        />
      );
    });
  };

  return (
    <>
      <div id="comments" className="content text-block comments">
        <span className="comments-number">
          {comments.count || 0} {t.comments.comments}
        </span>
        <CommentInput
          editorRef={editorRef}
          inputRef={inputRef}
          defaultValues={values[0]}
          avatar={avatar}
          onChange={handleChange}
          onSubmit={() => handlePostComment(0)}
          onRemove={onRemoveFile}
          validations={validations[0]}
          uploadProgress={uploadProgress}
          id={0}
          currentId={currentId}
          commentInputRef={commentInputRef}
        />
        {comments.list && comments.list.length ? (
          <ul className="post-list">{renderComments(getNestedList())}</ul>
        ) : (
          <div id="no-posts" className="no-posts">
            {t.comments.noPosts}
          </div>
        )}
      </div>
      {isDeleteModalVisible && (
        <DeleteModal onDelete={confirmDelete} onCancel={closeModal} />
      )}
    </>
  );
};

export default List;
