export const getAuthStateFromStorage = () => {
  try {
    const stringifiedState = localStorage.getItem("@paprac-web:authData");
    if (stringifiedState === null) {
      return undefined;
    }
    return JSON.parse(stringifiedState);
  } catch (error) {
    return undefined;
  }
};

export const setAuthStateToStorage = authData => {
  try {
    localStorage.setItem("@paprac-web:authData", JSON.stringify(authData));
  } catch (error) {}
};

export const removeAuthStateFromStorage = () => {
  return localStorage.removeItem("@paprac-web:authData");
};

export const getLanguageFromStorage = () => {
  try {
    const stringifiedState = localStorage.getItem("@paprac-web:lang");
    if (stringifiedState === null) {
      return undefined;
    }
    return JSON.parse(stringifiedState);
  } catch (error) {
    return undefined;
  }
};

export const setLanguageToStorage = lang => {
  try {
    localStorage.setItem("@paprac-web:lang", JSON.stringify(lang));
  } catch (error) {}
};
