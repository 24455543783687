import React from "react";

// TODO: mozda nam ova komponenta nece trebati jer je ista kao CoursePageHeader
const Header = ({ title = "", subtitle = "" }) => {
  return (
    <header className="site-header simple">
      <div className="content">
        <div className="text">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
      </div>
    </header>
  );
};

export default Header;
