import React from "react";
import HtmlEditor from "../../common/editor/HtmlEditor";
import useTranslations from "../../hooks/useTranslations";
import FileInput from "./FileInput";

const CommentInput = ({
  avatar,
  defaultValues,
  onChange = () => {},
  onSubmit = () => {},
  onRemove = () => {},
  uploadProgress = 0,
  validations,
  commentInputRef,
  editorRef,
  id,
  currentId,
  inputRef
}) => {
  const t = useTranslations();
  return (
    <div className="compose-wrapper flex" ref={commentInputRef}>
      <div className="avatar">
        <img data-role="user-avatar" src={avatar} alt="Avatar" />
      </div>
      <div className="textarea-wrapper">
        <HtmlEditor
          editorRef={editorRef}
          id={`editor-${id || 0}`}
          name="text"
          defaultValue={defaultValues.text}
        />
        <div className="comment-upload">
          <FileInput
            inputRef={inputRef}
            name="file"
            id={id}
            onChange={onChange}
            onRemove={onRemove}
            file={defaultValues.file}
            fileName={defaultValues.fileName}
          />
          {uploadProgress && id === currentId.current && (
            <div className="loader">
              <div
                className="loader-bar"
                style={{ width: `${uploadProgress || 0}%` }}
              >
                {`${t.comments.uploadingFile} ${uploadProgress}%` ||
                  (uploadProgress === 100 &&
                    `${t.comments.fileUploaded} ${uploadProgress}%`)}
              </div>
            </div>
          )}
          {validations && (
            <span className="validation">
              {!validations.type
                ? t.errors.fileType
                : !validations.size
                ? t.errors.fileSize
                : null}
            </span>
          )}
        </div>
        <button type="button" className="bttn" onClick={onSubmit}>
          {t.comments.submit}
        </button>
      </div>
    </div>
  );
};

export default CommentInput;
