import {
  GET_COMMENTS,
  GET_SELECTED_COMMENT,
  CREATE_COMMENT,
  UPDATE_COMMENT,
  DELETE_COMMENT,
  RESET_COMMENT_STATE,
  GET_FILE,
  SEND_EMAIL_NOTIFICATION,
  UPLOAD_FILE_PROGRESS
} from "./commentActions";

const INITIAL_STATE = {
  list: [],
  selectedItem: null,
  selectedFileUrl: null,
  isFetching: false,
  isFetched: false,
  isSaving: false,
  isSaved: false,
  isError: false,
  isReplySending: false,
  isReplySent: false,
  uploadProgress: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COMMENTS.REQUEST: {
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        isError: false
      };
    }
    case GET_SELECTED_COMMENT.REQUEST: {
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        isError: false
      };
    }
    case GET_FILE.REQUEST: {
      return {
        ...state,
        isFetching: true,
        isFetched: false,
        isError: false
      };
    }
    case CREATE_COMMENT.REQUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isError: false
      };
    }
    case UPDATE_COMMENT.REQUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isError: false
      };
    }
    case DELETE_COMMENT.REQUEST: {
      return {
        ...state,
        isSaving: true,
        isSaved: false,
        isError: false
      };
    }
    case SEND_EMAIL_NOTIFICATION.REQUEST: {
      return {
        ...state,
        isReplySending: true,
        isReplySent: false,
        isError: false
      };
    }
    case GET_COMMENTS.SUCCESS: {
      return {
        ...state,
        list: action.payload,
        isFetching: false,
        isFetched: true,
        isError: false
      };
    }
    case GET_SELECTED_COMMENT.SUCCESS: {
      return {
        ...state,
        selectedItem: action.payload,
        isFetching: false,
        isFetched: true,
        isError: false
      };
    }
    case GET_FILE.SUCCESS: {
      return {
        ...state,
        selectedFileUrl: action.payload.fileDownloadLink,
        isFetching: true,
        isFetched: false,
        isError: false
      };
    }
    case CREATE_COMMENT.SUCCESS: {
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        isError: false
      };
    }
    case UPDATE_COMMENT.SUCCESS: {
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        isError: false
      };
    }
    case DELETE_COMMENT.SUCCESS: {
      return {
        ...state,
        isSaving: false,
        isSaved: true,
        isError: false
      };
    }
    case SEND_EMAIL_NOTIFICATION.SUCCESS: {
      return {
        ...state,
        isReplySending: false,
        isReplySent: true,
        isError: false
      };
    }
    case UPLOAD_FILE_PROGRESS: {
      return {
        ...state,
        uploadProgress: action.payload
      };
    }
    case GET_COMMENTS.ERROR:
    case GET_SELECTED_COMMENT.ERROR:
    case GET_FILE.ERROR: {
      return {
        ...state,
        isFetching: false,
        isFetched: false,
        isError: true
      };
    }
    case CREATE_COMMENT.ERROR:
    case UPDATE_COMMENT.ERROR:
    case DELETE_COMMENT.ERROR: {
      return {
        ...state,
        isSaving: false,
        isSaved: false,
        isError: true
      };
    }
    case SEND_EMAIL_NOTIFICATION.ERROR: {
      return {
        ...state,
        isReplySending: false,
        isReplySent: false,
        isError: true
      };
    }

    case RESET_COMMENT_STATE: {
      return {
        ...state,
        selectedItem: null,
        selectedFileUrl: null,
        isFetching: false,
        isFetched: false,
        isSaving: false,
        isSaved: false,
        isError: false,
        uploadProgress: null
      };
    }

    default:
      return state;
  }
}
