export const getIsAuthenticating = state => state.auth.isLogging;
export const getIsAuthenticated = state => state.auth.isAuthenticated;
export const getAuthErrors = state => state.auth.errors;
export const getUserRole = state => state.auth.user?.role;
export const getUsername = state => state.auth.user?.userName;

export const hasCheckedUrlValidity = state =>
  state.auth.hasCheckedRegistrationUrlValidity;
export const getIsRegistrationUrlValid = state =>
  state.auth.registrationUrlValid;
export const getIsAdvancedFormFieldSetVisible = state =>
  state.auth.registrationAdvancedFormFieldSetVisible;

export const getCourses = state => state.auth.courses;
export const getCourseCodeLanguage = state => state.auth.courses[0]?.language;

export const getIsRegistering = state => state.auth.isRegistering;
export const getIsRegistered = state => state.auth.isRegistered;
