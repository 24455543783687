import { callApi } from "../../services/api/api";

import {
  getComments as getCommentsApi,
  getSelectedComment as getSelectedCommentApi,
  createComment as createCommentApi,
  updateComment as updateCommentApi,
  deleteComment as deleteCommentApi,
  getFileLinkForDownload as getFileLinkForDownloadApi,
  sendEmailNotificationOnReply as sendEmailNotificationOnReplyApi
} from "../apiConfig";
import generateActions from "../../services/utilities/generateActions";

export const GET_COMMENTS = generateActions("GET_COMMENTS");
export const GET_SELECTED_COMMENT = generateActions("GET_SELECTED_COMMENT");
export const CREATE_COMMENT = generateActions("CREATE_COMMENT");
export const UPDATE_COMMENT = generateActions("UPDATE_COMMENT");
export const DELETE_COMMENT = generateActions("DELETE_COMMENT");
export const GET_FILE = generateActions("GET_FILE");
export const UPLOAD_FILE_PROGRESS = "UPLOAD_FILE_PROGRESS";

export const SEND_EMAIL_NOTIFICATION = generateActions(
  "SEND_EMAIL_NOTIFICATION"
);

export const RESET_COMMENT_STATE = "RESET_COMMENT_STATE";

export const getComments = ({ data }) => {
  return {
    type: GET_COMMENTS.ACTION,
    promise: callApi(getCommentsApi(data))
  };
};
export const getSelectedComment = ({ id }) => {
  return {
    type: GET_SELECTED_COMMENT.ACTION,
    promise: callApi(getSelectedCommentApi(id))
  };
};

export const createComment = ({ data }) => {
  return {
    type: CREATE_COMMENT.ACTION,
    promise: callApi(createCommentApi(data))
  };
};
export const updateComment = ({ data }) => {
  return {
    type: UPDATE_COMMENT.ACTION,
    promise: callApi(updateCommentApi(data))
  };
};
export const deleteComment = id => {
  return {
    type: DELETE_COMMENT.ACTION,
    promise: callApi(deleteCommentApi(id))
  };
};

export const resetCommentState = () => {
  return {
    type: RESET_COMMENT_STATE
  };
};

export function getFileLinkForDownload(id) {
  return {
    type: GET_FILE.ACTION,
    promise: callApi(getFileLinkForDownloadApi(id))
  };
}
export function getFileUploadPercentage(progress) {
  return {
    type: UPLOAD_FILE_PROGRESS,
    payload: progress
  };
}

export function sendEmailNotificationOnReply({ data }) {
  return {
    type: SEND_EMAIL_NOTIFICATION.ACTION,
    promise: callApi(sendEmailNotificationOnReplyApi(data)),
    payload: data
  };
}

export function createCommentAndUpdateState({ data, pageData }) {
  return dispatch => {
    return dispatch(createComment({ data }))
      .then(() => dispatch(resetCommentState()))
      .then(() => dispatch(getComments({ data: pageData })));
  };
}

export function updateCommentAndUpdateState({ data, pageData }) {
  return dispatch => {
    return dispatch(updateComment({ data }))
      .then(() => dispatch(resetCommentState()))
      .then(() => dispatch(getComments({ data: pageData })));
  };
}

export function deleteCommentAndUpdateState({ id, data }) {
  return dispatch => {
    return dispatch(deleteComment(id))
      .then(() => dispatch(resetCommentState()))
      .then(() => dispatch(getComments({ data })));
  };
}
