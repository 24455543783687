import { SEND_EMAIL, RESET_CONTACT_STATE } from "./contactActions";

const INITIAL_STATE = {
  sending: false,
  sent: false,
  errors: null,
  isError: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case SEND_EMAIL.REQUEST: {
      return {
        ...state,
        sending: true,
        sent: false,
        isError: false
      };
    }

    case SEND_EMAIL.SUCCESS: {
      return {
        ...state,
        sending: false,
        sent: true,
        isError: false
      };
    }
    case SEND_EMAIL.ERROR: {
      return {
        ...state,
        sending: false,
        sent: false,
        errors: action.error?.response?.data?.errors || null,
        isError: true
      };
    }

    case RESET_CONTACT_STATE: {
      return {
        ...INITIAL_STATE
      };
    }

    default:
      return state;
  }
}
