import {
  GET_COURSE_DETAILS,
  GET_COURSE_LECTURE_LIST,
  GET_COURSE_LECTURE_DETAILS,
  GET_LECTURE_TEST,
  POST_LECTURE_TEST,
  POST_COURSE_FINAL_FORM
} from "./courseActions";

const INITIAL_STATE = {
  selectedItem: null,
  isItemFetching: false,
  isItemFetched: false,
  isItemFetchError: false,
  itemFetchErrorStatus: null,
  lecturesList: [],
  isLectureListFetching: false,
  isLectureListFetched: false,
  isLectureListFetchError: false,
  selectedLecture: null,
  isLectureFetching: false,
  isLectureFetched: false,
  isLectureFetchError: false,
  lectureFetchErrorStatus: null,
  selectedTest: null,
  isTestFetching: false,
  isTestFetched: false,
  isTestFetchError: false,
  testFetchErrorStatus: null,
  isTestSubmitting: false,
  isTestSubmitted: false,
  isTestSubmitError: false,
  isFinalFormSubmitting: false,
  isFinalFormSubmitted: false,
  finalFormSubmitError: false
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COURSE_DETAILS.REQUEST: {
      return {
        ...state,
        isItemFetching: true,
        isItemFetched: false,
        isItemFetchError: false,
        itemFetchErrorStatus: null
      };
    }

    case GET_COURSE_LECTURE_LIST.REQUEST: {
      return {
        ...state,
        isLectureListFetching: true,
        isLectureListFetched: false,
        isLectureListFetchError: false
      };
    }

    case GET_COURSE_LECTURE_DETAILS.REQUEST: {
      return {
        ...state,
        isLectureFetching: true,
        isLectureFetched: false,
        isLectureFetchError: false,
        lectureFetchErrorStatus: null,
        selectedTest: null,
        isTestFetching: false,
        isTestFetched: false,
        isTestFetchError: false,
        testFetchErrorStatus: null,
        isTestSubmitting: false,
        isTestSubmitted: false,
        isTestSubmitError: false
      };
    }

    case GET_LECTURE_TEST.REQUEST: {
      return {
        ...state,
        isTestFetching: true,
        isTestFetched: false,
        isTestFetchError: false,
        testFetchErrorStatus: null,
        isTestSubmitting: false,
        isTestSubmitted: false,
        isTestSubmitError: false
      };
    }

    case POST_LECTURE_TEST.REQUEST: {
      return {
        ...state,
        isTestSubmitting: true,
        isTestSubmitted: false,
        isTestSubmitError: false
      };
    }

    case POST_COURSE_FINAL_FORM.REQUEST: {
      return {
        ...state,
        isFinalFormSubmitting: true,
        isFinalFormSubmitted: false,
        finalFormSubmitError: false
      };
    }

    case GET_COURSE_DETAILS.SUCCESS: {
      return {
        ...state,
        selectedItem: action.payload,
        isItemFetching: false,
        isItemFetched: true,
        isItemFetchError: false
      };
    }

    case GET_COURSE_LECTURE_LIST.SUCCESS: {
      return {
        ...state,
        lecturesList: action.payload,
        isLectureListFetching: false,
        isLectureListFetched: true,
        isLectureListFetchError: false
      };
    }

    case GET_COURSE_LECTURE_DETAILS.SUCCESS: {
      return {
        ...state,
        selectedLecture: action.payload,
        isLectureFetching: false,
        isLectureFetched: true,
        isLectureFetchError: false,
        lectureFetchErrorStatus: null
      };
    }

    case GET_LECTURE_TEST.SUCCESS: {
      return {
        ...state,
        selectedTest: action.payload,
        isTestFetching: false,
        isTestFetched: true,
        isTestFetchError: false
      };
    }

    case POST_LECTURE_TEST.SUCCESS: {
      const {
        lectureLanguageId,
        previousLectureLanguageId,
        questions,
        ...rest
      } = action.payload;

      return {
        ...state,
        selectedLecture: {
          ...state.selectedLecture,
          canTakeTest: rest.canTakeTest
        },
        selectedTest: {
          ...state.selectedTest,
          ...rest,
          questions: state.selectedTest.questions.map(question => {
            const questionResults = questions.find(x => x.id === question.id);

            if (questionResults) {
              const { id, type, answers, ...rest } = questionResults;

              return {
                ...question,
                ...rest,
                answers: question.answers?.map(answer => {
                  const answerResults = questionResults.answers?.find(
                    x => x.id === answer.id
                  );

                  if (answerResults) {
                    return {
                      ...answer,
                      ...answerResults
                    };
                  }

                  return answer;
                })
              };
            }

            return question;
          })
        },
        isTestSubmitting: false,
        isTestSubmitted: true,
        isTestSubmitError: false
      };
    }

    case POST_COURSE_FINAL_FORM.SUCCESS: {
      return {
        ...state,
        isFinalFormSubmitting: false,
        isFinalFormSubmitted: true,
        finalFormSubmitError: false
      };
    }

    case GET_COURSE_DETAILS.ERROR: {
      return {
        ...state,
        isItemFetching: false,
        isItemFetched: false,
        isItemFetchError: true,
        itemFetchErrorStatus: action.error?.response?.data?.status
      };
    }

    case GET_COURSE_LECTURE_LIST.ERROR: {
      return {
        ...state,
        isLectureListFetching: false,
        isLectureListFetched: false,
        isLectureListFetchError: true
      };
    }

    case GET_COURSE_LECTURE_DETAILS.ERROR: {
      return {
        ...state,
        isLectureFetching: false,
        isLectureFetched: false,
        isLectureFetchError: true,
        lectureFetchErrorStatus: action.error?.response?.data?.status
      };
    }

    case GET_LECTURE_TEST.ERROR: {
      return {
        ...state,
        isTestFetching: false,
        isTestFetched: false,
        isTestFetchError: true,
        testFetchErrorStatus: action.error?.response?.data?.status
      };
    }

    case POST_LECTURE_TEST.ERROR: {
      return {
        ...state,
        isTestSubmitting: false,
        isTestSubmitted: false,
        isTestSubmitError: true
      };
    }

    case POST_COURSE_FINAL_FORM.ERROR: {
      return {
        ...state,
        isFinalFormSubmitting: false,
        isFinalFormSubmitted: false,
        finalFormSubmitError: true
      };
    }

    default:
      return state;
  }
}
