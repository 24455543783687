export const validateFileType = file => {
  const allowedFileExtensions = [
    ".jpg",
    ".png",
    ".jpeg",
    ".gif",
    ".pdf",
    ".docx",
    ".pptx",
    ".xlsx"
  ];
  let extension = file.name.slice(file.name.lastIndexOf("."));
  if (!allowedFileExtensions.includes(extension.toLowerCase())) {
    return false;
  }
  return true;
};

export const validateFileSize = file => {
  const maxAllowedFileSizeInBytes = 10485760;
  if (file.size > maxAllowedFileSizeInBytes) {
    return false;
  }
  return true;
};
