import React, { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

// import Loader from "../common/Loader";
import { getStaticPageDetails } from "./state/staticPageActions";
import {
  getSelectedStaticPage,
  getIsStaticPageLoading,
  getStaticPageFetchErrorStatus
} from "./state/staticPageSelectors";
import { getHomePageId } from "../app/state/appSelectors";
import HomePageHeader from "../common/header/HomePageHeader";
import InnerPageHeader from "../common/header/InnerPageHeader";
import Comments from "../comments/CommentList";
import CommentsLayout from "../comments/CommentLayout";
import useErrorStatusCheck from "../hooks/useErrorStatusCheck";
import useRedirectUrlCalculation from "../hooks/useRedirectUrlCalculation";
import { PAGE_TYPES } from "../services/constants/pageTypes";
import { courseTypes } from "../services/enums/courseTypes";

const StaticPage = ({ isUrlValid }) => {
  const { id } = useParams();
  const page = useSelector(getSelectedStaticPage);
  const isLoading = useSelector(getIsStaticPageLoading);
  const homePageId = useSelector(getHomePageId);
  const history = useHistory();
  const dispatch = useDispatch();
  const fetchError = useErrorStatusCheck({
    selectorFn: getStaticPageFetchErrorStatus
  });
  const redirectUrl = useRedirectUrlCalculation({
    pageType: PAGE_TYPES.static
  });

  useEffect(() => {
    if (
      isUrlValid &&
      (id || (!id && homePageId)) &&
      (!page ||
        (page &&
          ((id && page.id !== +id) ||
            (!id && homePageId && page.id !== homePageId))))
    ) {
      dispatch(getStaticPageDetails({ id: id || homePageId }));
    }
  }, [dispatch, isUrlValid, id, homePageId, page]);

  useEffect(() => {
    if (fetchError && !isLoading) {
      history.replace(redirectUrl);
    }
  }, [history, fetchError, redirectUrl, isLoading]);

  // We don't display loader because data loads fast
  // so the loader would be shown only as a flicker (bad UX).
  // if (isLoading) {
  //   return <Loader />;
  // }

  return (
    <>
      {!id ? (
        <HomePageHeader title={page?.title} subtitle={page?.subTitle} />
      ) : (
        <InnerPageHeader title={page?.title} subtitle={page?.subTitle} />
      )}

      <section id="siteContent" className="siteContent">
        <div
          className="content text-block"
          dangerouslySetInnerHTML={{
            __html: page?.text
          }}
        />
        {page?.courseTypeId === courseTypes.Advanced.id &&
          page?.hasComments &&
          id && (
            <CommentsLayout>
              <Comments staticPageLanguageId={page.id} />
            </CommentsLayout>
          )}
      </section>
    </>
  );
};

export default StaticPage;
