/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";

import useTranslations from "../hooks/useTranslations";

import logo from "../assets/img/logo.gif";

const Footer = () => {
  const t = useTranslations();

  return (
    <footer className="site-footer">
      <div className="content">
        <div className="address">
          <a href="http://www.paprac.org" className="logo">
            <img src={logo} title="PAPRAC" width="66" height="66" alt="logo" />
          </a>

          <p>
            {t.clientName}
            <br />
            Kraj Sv. Ivana 11
            <br />
            HR-21000 Split
            <br />
            CROATIA
            <br />
            Tel: (385) 21 340 470
            <br />
            Fax: (385) 21 340 490
            <br />
            e-mail: medopen@paprac.org
            <br />
            http://www.paprac.org
          </p>
        </div>

        <div className="social-icons">
          <a
            href="https://www.facebook.com/Coast.Day.Med/"
            target="_blank"
            rel="noopener noreferrer"
            className="icon fa-facebook"
            aria-label="Social icon facebook"
          />

          <a
            href="https://twitter.com/UNEP_PAPRAC"
            target="_blank"
            rel="noopener noreferrer"
            className="icon fa-twitter"
            aria-label="Social icon twitter"
          />

          <a
            href="https://www.youtube.com/channel/UCnNtdm9eJsFBLL5qsZOBLRw"
            target="_blank"
            rel="noopener noreferrer"
            className="icon fa-youtube"
            aria-label="Social icon youtube"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
