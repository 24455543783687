import { GET_STATIC_PAGE_DETAILS } from "./staticPageActions";

const INITIAL_STATE = {
  selectedItem: null,
  isItemFetching: false,
  isItemFetched: false,
  isItemFetchError: false,
  itemFetchErrorStatus: null
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_STATIC_PAGE_DETAILS.REQUEST: {
      return {
        ...state,
        isItemFetching: true,
        isItemFetched: false,
        isItemFetchError: false,
        itemFetchErrorStatus: null
      };
    }

    case GET_STATIC_PAGE_DETAILS.SUCCESS: {
      return {
        ...state,
        selectedItem: action.payload,
        isItemFetching: false,
        isItemFetched: true,
        isItemFetchError: false
      };
    }

    case GET_STATIC_PAGE_DETAILS.ERROR: {
      return {
        ...state,
        isItemFetching: false,
        isItemFetched: false,
        isItemFetchError: true,
        itemFetchErrorStatus: action.error?.response?.data?.status
      };
    }

    default:
      return state;
  }
}
